function carouselInit() {
    let carousel = document.querySelector("#testimonial-carousel");
    if (carousel) {
        let intervalAmount = parseInt(carousel.dataset.interval);
        /* Initialize Carousel */
        var paused = 0;
        $("#testimonial-carousel #myCarousel").carousel({
            interval: intervalAmount,
            pause: 0
        });
        /* Play trigger */
        $("#testimonial-carousel #toggleCarousel")
            .unbind("click")
            .click(function() {
                var state = paused ? "cycle" : "pause";
                paused = paused ? 0 : 1;
                $("#testimonial-carousel #myCarousel").carousel(state);
                $(this)
                    .find("i")
                    .toggleClass("fa-play fa-pause");
            });
    }
}

export default carouselInit;
