function imageCarouselHero() {
    $(function() {
        $(".image-carousel-hero").each(function($el) {
            $(".play-state-toggle", $el).on("click", function(e) {
                e.preventDefault();

                const toggle = $(this);
                const target = toggle.data("target");
                const state = $(target).data("bs.carousel").paused;

                $(target).carousel(state ? "cycle" : "pause");

                $(".carousel-inner", $(target)).attr("aria-live", state ? "polite" : "off");

                $(this)
                    .removeClass("play pause")
                    .addClass(() => (state ? "play" : "pause"));
            });
        });
    });
}

export default imageCarouselHero;
