export default () => {
    return {
        init() {
            const isiTitle = this.$refs.isiTitle.innerHTML;
            const isiCopy = this.$refs.isiCopy.innerHTML;
            const indicationTitle = this.$refs.indicationTitle.innerHTML;
            const indicationCopy = this.$refs.indicationCopy.innerHTML;

            this.$store.isiInformation = {
                isiTitle: isiTitle,
                isiCopy: isiCopy,
                indicationTitle: indicationTitle,
                indicationCopy: indicationCopy
            };
        }
    };
};
