function accessibilityInit() {
    function togglePhoneLinks() {
        let phoneLinks = document.querySelectorAll("a[href^='tel:']");
        phoneLinks.forEach(el => {
            el.setAttribute("tabindex", window.matchMedia("(max-width: 992px)").matches ? "0" : "-1");
        });
    }
    window.addEventListener("resize", togglePhoneLinks);
    togglePhoneLinks();
}

export default accessibilityInit;
