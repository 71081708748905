function updateProgressbar() {
    let progressBar = document.querySelectorAll(".dynamic-bar");
    if (progressBar.length > 0) {
        let activeCircle = document.querySelector(".progress-bar-list li.active").getAttribute("data-step");
        let active = document.querySelector(".carousel-inner.vertical > .item.active");
        let activeProgress = active
            .getAttribute("progress")
            .split(",")
            .map(Number);
        //solves for first element and review page on load
        if (activeProgress[0] == 0) {
            if (activeCircle == "personalInfo") {
                progressBar.forEach(bar => {
                    bar.style.width = "0%";
                });
                progressBar[0].style.width = "50%";
                return;
            } else if (activeCircle == "review") {
                progressBar.forEach(bar => {
                    bar.style.width = "100%";
                });
                return;
            }
        }
        let steps = [100 / document.querySelectorAll(".step-1").length, 100 / document.querySelectorAll(".step-2").length, 100 / document.querySelectorAll(".step-3").length, 100 / document.querySelectorAll(".step-4").length];
        //find out step number and name for active item
        let activeItem = activeProgress[0];
        let activeStep = activeProgress[1] - 1;
        //Set active width
        progressBar[activeItem].style.width = steps[activeItem] * activeStep + "%";

        //edge case of moving forward
        if (activeStep == 0) {
            progressBar[activeItem - 1].style.width = "100%";
        }
        let nextItem = active.nextElementSibling;
        if (!nextItem) {
            //solves for last element
            let activeCircle = document.querySelector(".progress-bar-list li.active").getAttribute("data-step");
            if (activeCircle == "review") {
                progressBar[activeItem].style.width = "100%";
                return;
            }
        } else {
            //edge case for backwards movement
            nextItem = nextItem.getAttribute("progress").split(",");
            if (nextItem[1] == 1) {
                progressBar[activeItem + 1].style.width = "0%";
            }
        }
    }
}

export default updateProgressbar;
