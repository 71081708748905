import createCalendar from "./ddgAddToCalender";
import { TempusDominus, DateTime } from "@eonasdan/tempus-dominus";
import DOMPurify from "dompurify";
function ddgAppointmentReminders() {
    // Create new Date instance
    const datepicker = new TempusDominus(document.getElementById("date"), {
        localization: {
            format: "L",
            locale: "en",
            dayViewHeaderFormat: { month: "long", year: "numeric" }
        },
        display: {
            icons: {
                previous: "glyphicon glyphicon-chevron-left",
                next: "glyphicon glyphicon-chevron-right"
            },
            components: {
                clock: false,
                hours: false,
                minutes: false,
                seconds: false
            },
            theme: "light",
            placement: "top"
        },
        restrictions: {
            minDate: new DateTime()
        },
        defaultDate: new Date()
    });
    //Overwritting the calender to have DOW be 1 letter
    document.getElementById("date").addEventListener("click", () => {
        document.querySelectorAll(".dow.no-highlight").forEach(day => {
            day.textContent = day.textContent[0];
        });
    });
    //disabling the text input
    $("input[name=date]").on("keydown keyup paste", function(e) {
        e.preventDefault();
    });

    $("#reminders-carousel").on("submit", function(e) {
        e.preventDefault();
        var date = datepicker.viewDate;
        date.setMinutes($("#Minute").val());
        date.setHours(parseInt($("#Hour").val()) + parseInt($('[name="ampm"]:checked').val()));
        date.setSeconds(0);
        var finalURL = DOMPurify.sanitize(document.URL);

        let isSpanish = document.getElementsByTagName("html")[0].getAttribute("lang");

        let descriptionEnglish = `This is a reminder you set up to remember your doctor appointment. Don't forget to discuss the results of your personalized discussion guide. LINK TO YOUR GUIDE:`;
        let descriptionSpanish = `Este es el recordatorio que programaste para recordar tu cita con el médico. No olvides conversar sobre los resultados de tu guía de discusión personalizada para hablar con tu médico.`;
        let appointmentEnglish = "Appointment Reminder";
        let appointmentSpansih = "Recordatorio de cita";

        let description = isSpanish === "es-US" ? descriptionSpanish : descriptionEnglish;
        let appointment = isSpanish === "es-US" ? appointmentSpansih : appointmentEnglish;
        var calendarItems = createCalendar({
            data: {
                title: appointment,
                start: date,
                duration: 60,
                description: `${description} ${finalURL}`
            }
        });
        var calType = "." + $('[name="calendar-type"]:checked').attr("id"),
            href = $(calendarItems)
                .find(calType)
                .attr("href");

        if (calType == ".google" || calType == ".yahoo") {
            $("#thirdPartyMod").modal("show");
            $(".websiteBtn").data("url", href);
        } else {
            window.open(DOMPurify.sanitize(href), "_blank");
        }
    });
}

export default ddgAppointmentReminders;
