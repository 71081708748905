import DOMPurify from "dompurify";

const getSanitizedURL = (url, hasProtocol = false) => {
    const newUrl = new URL(DOMPurify.sanitize(url));
    //sanitize params
    let searchParams = newUrl.searchParams;
    searchParams.forEach((value, key) => {
        if (!DOMPurify.isValidAttribute("a", "href", value)) {
            searchParams.delete(key);
        }
    });
    newUrl.search = searchParams;
    if (hasProtocol) {
        return DOMPurify.sanitize(newUrl.href);
    } else {
        //makes new url component, so we can grab methods and remove protocols ("http, https etc")
        return DOMPurify.sanitize(newUrl.hostname + newUrl.pathname + newUrl.search);
    }
};

function init() {
    var emailDocModal = $("#email-doc-modal");
    var inputEmail = emailDocModal.find("input[name='email']");
    var regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    inputEmail.on("keyup", function() {
        if (inputEmail.val().match(regexEmail)) {
            emailDocModal.find(".errorLb").hide();
            inputEmail.removeClass("hasError");
        }
    });

    emailDocModal.on("show.bs.modal", function() {
        emailDocModal.find(".form-content").removeClass("hidden");
        emailDocModal.find(".form-success").addClass("hidden");
        inputEmail.val("").removeClass("hasError");
        emailDocModal.find(".errorLb").hide();
    });

    emailDocModal.find("form").on("submit", function(e) {
        e.preventDefault();
        const el = $(this);
        const urlEmail = el.attr("action");
        const emailField = el.find("input[name='email']");
        var v2Override = el.find("input[name='useV2']").val();
        var language = document
            .getElementsByTagName("html")[0]
            .getAttribute("lang")
            .toUpperCase()
            .replace("-", "_");
        if (emailField && emailField.val()) {
            emailDocModal.find(".errorLb").hide();
            inputEmail.removeClass("hasError");
            const title = el.find("input[name='title']").val();
            const jobCode = el.find("input[name='jobCode']").val();
            const lastUpdate = el.find("input[name='lastUpdate']").val();
            const indication = el.find("input[name='indication']").val();
            const emailAddress = emailField.val();
            const formData = new URLSearchParams();
            if (indication.length > 0) {
                formData.append("indication", indication);
            }
            formData.append("email", emailAddress);
            if (v2Override === "true") {
                formData.append("displayName", title);
                formData.append("language", language);
            } else {
                formData.append("title", title);
                formData.append("jobCode", jobCode);
                formData.append("lastUpdate", lastUpdate);
            }
            if (document.querySelector("body.doctor-discussion-guide")) {
                if (v2Override === "true") {
                    var windowHref = getSanitizedURL(window.location);
                    formData.append("displayUrl", windowHref);
                } else {
                    formData.append("document", getSanitizedURL(window.location.href, true));
                    formData.append("template", "doctor-discussion-guide");
                }
            } else {
                const finalURL = getSanitizedURL(el.find("input[name='document']").val());
                if (v2Override === "true") {
                    formData.append("displayUrl", finalURL);
                } else {
                    formData.append("document", finalURL);
                    formData.append("template", "doc-mailer");
                }
            }

            $.ajax({
                type: "GET",
                url: "/.rest/freshaddress/validateEmail?email=" + emailAddress,
                success: function(resp) {
                    if (resp.valid) {
                        $.ajax({
                            type: "POST",
                            url: urlEmail,
                            data: formData.toString(),
                            contentType: "application/x-www-form-urlencoded",
                            async: false,
                            success: function() {
                                emailDocModal.find(".form-content").addClass("hidden");
                                emailDocModal.find(".form-success").removeClass("hidden");
                            },
                            error: function(msg) {
                                alert("Error sending email:" + msg);
                                return false;
                            }
                        });
                    } else {
                        emailDocModal.find(".errorLb").show();
                        emailField.addClass("hasError");
                    }
                }
            });
        } else {
            emailDocModal.find(".errorLb").show();
            emailField.addClass("hasError");
        }
    });
}

export default { init };
