const MMIT_API_ENDPOINT = "https://api.mmitnetwork.com";
const MMIT_API_PROJECT_ID = 933;

function createApiEndpoint(string) {
    return MMIT_API_ENDPOINT + string;
}

export const MMIT_API_ENDPOINTS = {
    formulary: {
        coverage: createApiEndpoint("/Formulary/v1/Coverage?"),
        derivedField: createApiEndpoint("/Formulary/v1/DerivedField?"),
        geographic: {
            cbsas: createApiEndpoint("/Formulary/v1/Geographic/CBSAs?ZipCode="),
            zips: createApiEndpoint("/Formulary/v1/Geographic/Zips")
        },
        notes: {
            structuredPA: createApiEndpoint("/Formulary/v1/Notes/StructuredNotesPA?"),
            structuredST: createApiEndpoint("/Formulary/v1/Notes/StructuredNotesST?")
        },
        parents: createApiEndpoint("/Formulary/v1/Parents?Name="),
        plans: createApiEndpoint("/Formulary/v1/Plans?"),
        products: createApiEndpoint("/Formulary/v1/Products?Name="),
        standardCriteria: createApiEndpoint("/Formulary/v1/StandardCriteria?"),
        unifiedTiers: createApiEndpoint("/Formulary/v1/Coverage/UnifiedTiers")
    },
    formTrak: {
        coverage: createApiEndpoint("/FormTrak/v1/Projects/" + MMIT_API_PROJECT_ID + "/Products/") /**{ProductId}/PlanCoverages**/,
        products: createApiEndpoint("/FormTrak/v1/Projects/" + MMIT_API_PROJECT_ID + "/Products")
    },
    token: createApiEndpoint("/Token")
};
