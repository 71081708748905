export default function scrollToElement(el) {
    function handleUserScroll() {
        $("html, body").stop(); //stop scroll animation if user scrolls
    }
    let page = $("html, body"),
        body = $("body");

    if (typeof el === "string") el = $(el);

    //listen for user scroll
    page.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", handleUserScroll);

    return page.animate(
        {
            scrollTop: el.offset().top
        },
        {
            progress: function() {
                body.removeClass("header-sticky").addClass("header-hide");
            },
            complete: function() {
                body.removeClass("header-sticky").addClass("header-hide");
                setTimeout(function() {
                    window.scrollBy(0, 2);
                }, 10);
                page.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", handleUserScroll); //remove scroll listener
            }
        }
    );
}
