//is CRM
let isCrm = document.location.href.indexOf("takingdupixent") && document.body.classList.contains("crm") ? true : false;
//create cookie
function createCookie(name, value) {
    var expires = "";
    document.cookie = name + "=" + value + "; " + expires + "; path=/";
}
//read cookie
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

//stop animated scroll on user scroll
function handleUserScroll() {
    $("html, body").stop(); //stop scroll animation if user scrolls
}

function scrollToEl(el) {
    var page = $("html, body"),
        body = $("body");

    if (typeof el === "string") el = $(el);

    //listen for user scroll
    page.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", handleUserScroll);

    return page.animate(
        {
            scrollTop: el.offset().top
        },
        {
            progress: function() {
                body.removeClass("header-sticky").addClass("header-hide");
            },
            complete: function() {
                body.removeClass("header-sticky").addClass("header-hide");
                page.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", handleUserScroll); //remove scroll listener
            }
        }
    );
}

function init() {
    var crmIsiReadMoreText = typeof readmore === "undefined" ? "More" : "";

    if (!$("footer .score-footer-wrapper").length) return;

    let clonedFooter = $("footer .score-footer-wrapper").clone();

    clonedFooter
        .addClass("sticky invisible")
        .appendTo("footer")
        .find(".isi")
        .attr("id", "");

    $(window)
        .on("scroll resize", function() {
            var stickyOffset, scrollDest;

            if (isCrm) {
                stickyOffset = $(".crm-isi.sticky").position().top;
                scrollDest = $(".crm-isi").offset().top - $(document).scrollTop();
            } else {
                stickyOffset = $(".score-footer-wrapper.sticky").position().top;
                scrollDest = $("#isi").offset().top - $(document).scrollTop();
            }

            stickyOffset <= scrollDest ? $(".score-footer-wrapper.sticky").removeClass("invisible") : $(".score-footer-wrapper.sticky").addClass("invisible");
        })
        .trigger("resize");

    var indication = readCookie("indication");

    if (readCookie(`${indication}-isi-collapsed`)) $("html").addClass("collapsed-sticky-isi");

    $(".score-footer-wrapper.sticky:not(.crm-isi.sticky) .isi .container").prepend('<span class="sticky-isi-toggles"><button class="expand-isi" aria-label="expand important safety information"><i class="fa fa-plus-circle"></i></button><span class="sep"></span><button class="collapse-isi" aria-label="collapse important safety information"><i class="fa fa-minus-circle"></i></button></span>');

    $(".crm-isi.sticky")
        .on("click", function(e) {
            e.preventDefault();
            scrollToEl(".isi:not(.sticky)");
        })
        .find(".container")
        .append(`<a class="read-more spanish-no-display" href="#"><strong>${crmIsiReadMoreText}</strong> <i class="fa fa-chevron-circle-down"></i></a>`);

    $(".expand-isi").on("click", function(e) {
        e.preventDefault();

        //remove collapsed class and add expanded
        $("html")
            .removeClass("collapsed-sticky-isi")
            .addClass("expanded-sticky-isi");

        //scroll sticky ISI content back to the top on open.
        $(".isi.sticky .score-page").scrollTop(0);

        setExpandedStickyHeight();

        //close PIXI
        $("#WAC__headerOnlyClose-close").trigger("click");

        //set cookie that ISI has been interacted
        createCookie(`${indication}-isi-collapsed`, true);
    });

    $(".collapse-isi").on("click", function(e) {
        e.preventDefault();

        //reset sticky ISI height
        $(".score-footer-wrapper.sticky").css({
            height: function() {
                return $(this).outerHeight();
            },
            top: ""
        });

        setTimeout(function() {
            $(".score-footer-wrapper.sticky").css("height", "");
            //remove expanded class and add collapsed
            $("html")
                .removeClass("expanded-sticky-isi")
                .addClass("collapsed-sticky-isi");
        }, 100);

        //set cookie that ISI has been interacted
        createCookie(`${indication}-isi-collapsed`, true);
    });

    $(window)
        .on("scroll resize", function() {
            var stickyOffset = $(".score-footer-wrapper.sticky").position().top,
                scrollDest = $(".score-footer-wrapper").offset().top - $(document).scrollTop();

            if (stickyOffset > scrollDest) {
                $("html").addClass("collapsed-sticky-isi");
                createCookie(`${indication}-isi-collapsed`, true);
            }
        })
        .trigger("resize");

    function setExpandedStickyHeight() {
        var headerRect = $("header")[0].getBoundingClientRect();

        //calculate the headers bottom edge distance from the top of the viewport
        let headerBottomOffsetTop = headerRect.top + headerRect.height - 1;

        //if that value is negative, set to 0
        if (headerBottomOffsetTop < 0) headerBottomOffsetTop = -1;

        //set height of ISI so the top edge meets the header
        $(".score-footer-wrapper.sticky")
            .stop(true, false)
            .animate(
                {
                    top: headerBottomOffsetTop
                },
                200
            )
            .css({ height: "auto" });
    }
}

export default { init };
