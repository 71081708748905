import removeTrailingSlash from "../utils/removeTrailingSlash";

export default function PageBlocked(pages) {
    let pageArray = pages.split(",");
    const newPageArray = pageArray.map(node => {
        return removeTrailingSlash(node);
    });

    const pathname = removeTrailingSlash(window.location.pathname);

    // Check for emtpy array
    //  Array gets built even if emtpy if we are at root of site it will be considered blocked
    const emtpyArr = newPageArray[0] == "";
    if (newPageArray.length == 1 && emtpyArr) {
        return false;
    } else {
        return newPageArray.includes(pathname);
    }
}
