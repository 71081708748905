export const MMIT_API_CONFIG = {
    atopicDermatitis: {
        productId: 226233,
        derivedFieldId: 656,
        indication: {
            id: 287,
            name: "Atopic Dermatitis (Eczema)"
        }
    },
    asthma: {
        productId: 233685,
        derivedFieldId: 1189,
        indication: {
            id: 322,
            name: "Asthma (oral)"
        }
    },
    nasalPolyposis: {
        productId: 233685,
        indication: {
            id: 406,
            name: "Nasal Polyposis"
        }
    },
    eosinophilicEsophagitis: {
        productId: 226233,
        indication: {
            id: 569,
            name: "Eosinophilic Esophagitis"
        }
    },
    prurigoNodularis: {
        productId: 226233,
        indication: {
            id: 617,
            name: "Prurigo Nodularis"
        }
    }
};
