export default function ajaxRequest(request) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(request.method || "GET", request.url);
        if (request.headers) {
            Object.keys(request.headers).forEach(key => {
                xhr.setRequestHeader(key, request.headers[key]);
            });
        }
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response);
            } else {
                reject(new Error(`${xhr.status} ${xhr.statusText}`));
            }
        };
        xhr.onerror = () => reject(new Error(`${xhr.status}:${xhr.statusText}`));
        xhr.send(request.body);
    });
}
