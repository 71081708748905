import ajaxRequest from "../../utils/ajaxRequest";
let loadingIntervalId = null;

export const SetShowElement = (doShow, element) => {
    if (doShow) {
        element.classList.remove("hidden");
        element.removeAttribute("aria-hidden");
    } else {
        element.classList.add("hidden");
        element.setAttribute("aria-hidden", "true");
    }
};

export const SetShowZipCodeError = (doShow, elements) => {
    SetShowElement(doShow, elements.zipCodeErrorMessage);

    if (doShow) {
        elements.zipCodeContainer.classList.add("error");
    } else {
        elements.zipCodeContainer.classList.remove("error");
    }
};

export const SetShowLoading = (doShow, elements) => {
    SetShowElement(doShow, elements.loadingMessageContainer);
    if (doShow) {
        elements.app.classList.add("loading");

        loadingIntervalId = setInterval(function() {
            const innerText = elements.loadingMessageText.innerText;

            if (innerText.indexOf("Finding Results") !== -1) {
                elements.loadingMessageText.innerText = "Almost There";
            } else {
                elements.loadingMessageText.innerText = "Finding Results";
            }
        }, 3000);
    } else {
        elements.app.classList.remove("loading");
        elements.loadingMessageText.innerText = "Finding Results";

        if (loadingIntervalId !== null) {
            clearInterval(loadingIntervalId);
            loadingIntervalId = null;
        }
    }
};

export const SetShowError = (doShow, elements) => {
    SetShowElement(!doShow, elements.mmitWrapper);
    SetShowElement(doShow, elements.errorMessageContainer);

    if (doShow) {
        elements.app.setAttribute("disabled", "");
    } else {
        elements.app.removeAttribute("disabled");
    }
};

export const SetPlanSelectEnabled = (isEnabled, elements) => {
    if (isEnabled) {
        elements.insurancePlanContainer.removeAttribute("disabled");
        elements.insurancePlanSelect.removeAttribute("disabled");
        elements.insurancePlanCombobox.removeAttribute("disabled");
        $(".ui.dropdown").removeClass("disabled");
    } else {
        elements.insurancePlanContainer.setAttribute("disabled", "");
        elements.insurancePlanSelect.setAttribute("disabled", "");
        elements.insurancePlanCombobox.setAttribute("disabled", "");
        $(".ui.dropdown").addClass("disabled");
        $(elements.insurancePlanSelect).suidropdown("clear");
    }
};

export const SetShowCoverageResult = (doShow, elements, planCoverageData, index) => {
    SetShowElement(false, elements.resultMessageContainer);
    SetShowElement(false, elements.coverageResultCovered);
    SetShowElement(false, elements.coverageResultNotCovered);
    SetShowElement(false, elements.coverageResultCoveredMoreInfo);
    SetShowElement(false, elements.coverageResultMissingInfo);

    if (doShow) {
        SetShowElement(true, elements.resultMessageContainer);
        const selectedPlanCoverage = planCoverageData[index];

        try {
            if (selectedPlanCoverage.unifiedTierId === 11) {
                SetShowElement(true, elements.coverageResultNotCovered);
            } else if (selectedPlanCoverage.unifiedTierId === 12 || selectedPlanCoverage.unifiedTierId === 13) {
                SetShowElement(true, elements.coverageResultMissingInfo);
            } else {
                if (selectedPlanCoverage.priorAuthorization || selectedPlanCoverage.stepTherapy) {
                    SetShowElement(true, elements.coverageResultCoveredMoreInfo);
                } else {
                    SetShowElement(true, elements.coverageResultCovered);
                }
            }
        } catch (error) {
            SetShowElement(true, elements.coverageResultMissingInfo);
        }
    }
};

export const SetSubmitButtonEnabled = (isEnabled, elements) => {
    if (isEnabled) {
        elements.submitButton.removeAttribute("disabled");
    } else {
        elements.submitButton.setAttribute("disabled", "");
    }
};

export const UpdateMonth = element => {
    if (element) {
        const m_names = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const d = new Date();
        const month = m_names[d.getMonth()];
        const year = d.getFullYear();
        const textLastP = element.textContent || element.innerText;
        element.innerHTML = `${textLastP} ${month} ${year}.`;
    }
};

export const RequestFn = (url, headers) => {
    const promiseObj = new Promise(resolve => {
        ajaxRequest({
            url,
            headers
        })
            .then(res => {
                if (!res) {
                    resolve(false);
                    return;
                }
                const data = JSON.parse(res);
                resolve(data);
            })
            .catch(() => {
                resolve(false);
            });
    });
    return promiseObj;
};
