import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch/lite";
import DOMPurify from "dompurify";

let autoComplete = config => {
    return {
        open: false,
        showModal: false,
        hideOnModal: false,
        toggleModalSearch() {
            this.open = !this.open;

            let advancedSearchInputForm = document.querySelector(".ContentSectionItemWrapper--desktop .aa-Form");

            if (advancedSearchInputForm) {
                advancedSearchInputForm.reset();
            }

            if (window.innerWidth <= 991) {
                this.hideOnModal = !this.hideOnModal;
                this.showModal = !this.showModal;
                this.moveElem();
            }

            setTimeout(() => {
                document.querySelector(".aa-Form .aa-Input").focus();
            }, 500);
        },
        moveElem() {
            let modal = document.querySelector(".transparent-background");
            let advancedSearch = document.querySelector(".ContentSectionItemWrapper--desktop");
            if (this.open) {
                advancedSearch.classList.add("showSearchModal");
                modal.prepend(advancedSearch);
                document.body.classList.add("prevent-scroll");
            } else {
                advancedSearch.classList.remove("showSearchModal");
                let utilNav = document.querySelector(".navbar-nav .search-box");
                let removed = modal.removeChild(advancedSearch);
                let lastChild = utilNav.lastElementChild;
                utilNav.insertBefore(removed, lastChild);
                document.body.classList.remove("prevent-scroll");
            }
        },
        config: {
            algoliaAppId: "",
            algoliaAuthenticatedKey: "",
            algoliaIndexName: "",
            searchResultsPage: "",
            ...config
        },

        mediaQueryFunction(x) {
            if (x.matches) {
                // if equal or below 991px
                if (this.open) {
                    this.open = false;
                }
            } else {
                // greater than 991px
                if (this.showModal) {
                    this.showModal = false;
                    this.hideOnModal = false;
                    this.open = false;
                    this.moveElem();
                }
            }
        },

        init() {
            let searchClient = algoliasearch(this.config.algoliaAppId, this.config.algoliaAuthenticatedKey);
            const indexName = this.config.algoliaIndexName;
            const searchResultsPage = this.config.searchResultsPage;
            let self = this;
            autocomplete({
                container: ".as-autocomplete",
                openOnFocus: false,
                insights: true,
                detachedMediaQuery: "none",
                onSubmit(item) {
                    this.goTo(item.state.query);
                },
                getSources() {
                    return [
                        {
                            sourceId: "products",
                            getItemInputValue: ({ item }) => item.query,
                            getItems({ query }) {
                                return getAlgoliaResults({
                                    searchClient,
                                    queries: [
                                        {
                                            indexName: indexName,
                                            query,
                                            params: {
                                                hitsPerPage: 4
                                            }
                                        }
                                    ]
                                });
                            },
                            templates: {
                                item({ item, html }) {
                                    return html`
                                        <a href="${DOMPurify.sanitize(window.location.origin) + searchResultsPage}?q=${DOMPurify.sanitize(item.query)}" class="aa-ItemLink returnItems">${DOMPurify.sanitize(item.query)}</a>
                                    `;
                                }
                            }
                        }
                    ];
                },
                goTo(item) {
                    let sanitizedItem = DOMPurify.sanitize(item);
                    let sanitizedOrigin = DOMPurify.sanitize(window.location.origin);
                    let hrefString = sanitizedOrigin + searchResultsPage + "?q=" + sanitizedItem;
                    //set location href
                    window.location.href = DOMPurify.sanitize(hrefString);
                    return;
                }
            });

            let mediaQuery = window.matchMedia("(max-width: 991px)");
            this.mediaQueryFunction(mediaQuery);
            mediaQuery.addEventListener("change", function() {
                self.mediaQueryFunction(mediaQuery);
            });
        }
    };
};

window.autoComplete = autoComplete;
