import "./coverageTool/sementic";

import GetAuthToken from "./coverageTool/requestToken";
import { AUTH_HEADERS } from "./coverageTool/configParams";
import { MMIT_API_CONFIG } from "./coverageTool/_MMIT_API_CONFIG";
import { MMIT_API_ENDPOINTS } from "./coverageTool/_MMIT_API_ENDPOINTS";

import { SetShowZipCodeError, SetShowCoverageResult, SetPlanSelectEnabled, SetSubmitButtonEnabled, SetShowLoading, SetShowError, UpdateMonth, RequestFn } from "./coverageTool/commonFunction";
import getOffset from "../utils/getOffset";

let planCoverageData = [];
let apiChannelId = 1;
let apiConfig = null;

const QUERY_EDGE_CASES = {
    InvalidZip: ""
};

function initializeComboboxNew(elements) {
    $.fn.suidropdown.settings.message.noResults = 'We couldn’t find a result for that plan name. Please call <strong><em>DUPIXENT MyWay</em></strong> at <a href="tel:18443874936" class="text-nowrap">1-844-387-4936</a>, option 1, for help with your insurance benefits.';
    const insurancePlanSelect = $('select[name="insuranceplan"]');
    insurancePlanSelect.suidropdown({
        placeholder: "TYPE OR SELECT",
        clearable: true,
        match: "text",
        onChange: function(value, text) {
            SetSubmitButtonEnabled(text !== "", elements);
        },
        onNoResults: function() {
            SetSubmitButtonEnabled(false, elements);
            return true;
        },
        onShow: function() {
            SetSubmitButtonEnabled(false, elements);
        },
        onHide: function() {
            SetSubmitButtonEnabled($(this).suidropdown("get value").length, elements);
        }
    });

    elements.insurancePlanCombobox = elements.insurancePlanContainer.querySelector("input.search");
}

class CostCoverageTool {
    constructor() {
        this.costCoverageTool = document.querySelector("#cost-and-coverage-tool");
        if (!this.costCoverageTool) return;
        this.appContainer = document.querySelector("[data-app-container]");
        this.mmitWrapper = document.querySelector(".mmit-tool-wrap");
        this.loadingMessageContainer = document.querySelector("[data-loading-message]");
        this.resultMessageContainer = document.querySelector("[data-result-message]");
        this.errorMessageContainer = document.querySelector("[data-error-message]");
        this.app = this.appContainer.querySelector("[data-app]");
        this.insuranceTypeHeading = document.querySelectorAll("[data-insurance-type]");
        this.form = this.app.querySelector("form[data-form]");
        this.submitButton = this.form.querySelector('button[type="submit"]');
        this.zipCodeContainer = this.form.querySelector("[data-zipcode-container]");
        this.zipCodeInput = this.zipCodeContainer.querySelector('input[name="zipcode"]');
        this.zipCodeSubmitButton = this.zipCodeContainer.querySelector("button[data-submit-zipcode]");
        this.insurancePlanContainer = this.form.querySelector("[data-plan-container]");
        this.insurancePlanSelect = this.form.querySelector('select[name="insuranceplan"]');
        this.zipCodeTooltip = this.form.querySelector("#zipcode-tooltip");
        this.zipCodeTooltipIcon = this.form.querySelector('[data-tooltip-icon="zipcode"]');
        this.insurancePlanTooltip = this.form.querySelector("#insuranceplan-tooltip");
        this.insurancePlanTooltipIcon = this.form.querySelector('[data-tooltip-icon="insuranceplan"]');
        this.zipCodeErrorMessage = this.form.querySelector('[data-form-error="zipcode"]');
        this.coverageResultCovered = this.resultMessageContainer.querySelector('[data-coverage-result="covered"]');
        this.coverageResultNotCovered = this.resultMessageContainer.querySelector('[data-coverage-result="not-covered"]');
        this.coverageResultCoveredMoreInfo = this.resultMessageContainer.querySelector('[data-coverage-result="covered-more-info"]');
        this.coverageResultMissingInfo = this.resultMessageContainer.querySelector('[data-coverage-result="missing-info"]');
        this.loadingMessageText = this.loadingMessageContainer.querySelector("[data-loading-message-text]");
        this.allBtnCoverage = this.costCoverageTool.querySelectorAll(".coverage-buttons .btn");
        this.insurancePlanCombobox = "";
        this.preferedDetailLastChild = document.querySelector("[data-update-date] div:last-child") || document.querySelector("[data-update-date] p:last-child");

        this.init();
    }

    onResetCopayForm() {
        const $this = this;
        $this.form.reset();
        SetShowError(false, $this);
        SetShowLoading(false, $this);
        SetShowCoverageResult(false, $this);
        SetPlanSelectEnabled(false, $this);
        SetSubmitButtonEnabled(false, $this);
        SetShowZipCodeError(false, $this);
    }

    initCarousel() {
        const $this = this;
        var currentSlide = $("#coverage-tool .active"),
            previousSlide;

        $("[data-stat]").on("click", function() {
            $(".stat-graphic .number").text($(this).data("stat") + "%");
        });

        $("#coverage-tool")
            .on("slide.bs.carousel", function(e) {
                const coverageTool = document.querySelector("#coverage-tool");
                if ($(window).width() < 768) {
                    window.scrollTo({
                        top: getOffset(coverageTool),
                        behavior: "smooth"
                    });
                }

                $(".carousel-inner").css("height", function() {
                    return $(this).outerHeight();
                });

                previousSlide = currentSlide;
                currentSlide = $(e.relatedTarget);

                if (currentSlide.hasClass("mmit-tool")) currentSlide.find(".btn.prev").attr("data-slide-to", previousSlide.index());

                setTimeout(function() {
                    var next_h = $(e.relatedTarget).outerHeight();
                    $(".carousel-inner").css("height", next_h);
                }, 10);
            })
            .on("slid.bs.carousel", function() {
                $(".carousel-inner").css("height", "auto");
                if (previousSlide.hasClass("mmit-tool")) $this.onResetCopayForm($this);
            });
    }

    initTooltip() {
        window.addEventListener("load", function() {
            $('.label-container [data-toggle="tooltip"]').tooltip({
                placement: "top",
                html: true,
                trigger: "hover",
                container: "body"
            });
        });
    }

    onSetInsuranceTypeHeading(text) {
        [].forEach.call(this.insuranceTypeHeading, div => {
            div.innerText = text;
        });
    }

    onPopulatePlanSelect(elements) {
        let options = "";
        for (let i = 0, dataLength = planCoverageData.length; i < dataLength; i++) {
            options += '<option value="' + i + '">' + planCoverageData[i].name + "</option>";
        }
        elements.insurancePlanSelect.innerHTML = "<option value=''>TYPE OR SELECT</option>" + options;
    }

    async initializeCopayForm(indicationKey, insuranceChannelId, insuranceType) {
        const $this = this;
        apiConfig = MMIT_API_CONFIG[indicationKey];
        apiChannelId = insuranceChannelId;
        $this.onSetInsuranceTypeHeading(insuranceType);
        initializeComboboxNew($this);
    }

    onHandleCoverageClick() {
        const $this = this;
        $this.allBtnCoverage.forEach(node => {
            node.addEventListener("click", () => {
                const channelId = node.dataset.channelId;
                const coverageType = node.dataset.coverageType;
                $this.initializeCopayForm("asthma", channelId, coverageType);
            });
        });
    }

    onGetInsuranceOptions(zipCode) {
        const url = MMIT_API_ENDPOINTS.formulary.geographic.cbsas + zipCode;
        return RequestFn(url, AUTH_HEADERS);
    }

    onRequestInsuranceCoverage(productId, channelId, areaQuery) {
        const url = MMIT_API_ENDPOINTS.formulary.coverage + "ProductId=" + productId + "&ChannelId=" + channelId + areaQuery + "&Options=PlanCoverages";
        return RequestFn(url, AUTH_HEADERS);
    }
    onRequestInsurancePlans(channelId, areaQuery) {
        const url = MMIT_API_ENDPOINTS.formulary.plans + "&ChannelId=" + channelId + areaQuery;
        return RequestFn(url, AUTH_HEADERS);
    }

    onUpdateCoverageData(plans, planCoverages) {
        planCoverageData = [];

        for (let i = 0, plansLength = plans.length; i < plansLength; i++) {
            const plan = plans[i];
            const planId = plan["PlanId"];
            const filteredPlanCoverages = planCoverages.filter(function(data) {
                return data.PlanId === planId;
            });

            if (!filteredPlanCoverages.length) {
                return;
            }

            const planCoverage = filteredPlanCoverages[0];
            const planData = {
                id: plan["PlanId"],
                name: plan["Name"],
                controllerId: plan["ControllerId"],
                unifiedTierId: planCoverage["UnifiedTierId"],
                stepTherapy: planCoverage["StepTherapy"],
                priorAuthorization: planCoverage["PriorAuthorization"]
            };

            planCoverageData.push(planData);
        }
        planCoverageData.sort(function(a, b) {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA > nameB) {
                return 1;
            } else if (nameA < nameB) {
                return -1;
            } else {
                return 0;
            }
        });
    }

    onRequestZipCodes() {
        const url = MMIT_API_ENDPOINTS.formulary.geographic.zips;
        return RequestFn(url, AUTH_HEADERS);
    }

    async onGetCoverageData(stateId, cbsaId) {
        const $this = this;
        const productId = apiConfig.productId;
        const channelId = apiChannelId;
        const areaQuery = stateId ? "&StateId=" + stateId : "&CBSAId=" + cbsaId;

        const getCoverageAwait = $this.onRequestInsuranceCoverage(productId, channelId, areaQuery);
        const getPlansAwait = $this.onRequestInsurancePlans(channelId, areaQuery);

        const getCoverage = await getCoverageAwait;
        const getPlans = await getPlansAwait;
        if (getCoverage && getPlans) {
            $this.onUpdateCoverageData(getPlans.Plans, getCoverage.PlanCoverages);
            return true;
        } else {
            return false;
        }
    }

    async onZipCodeSubmitListener(event) {
        const $this = this;
        const IS_ALL_DIGITS_REGEX = /^[0-9]+$/;
        const ZIP_CODE_MAX_LENGTH = 5;
        const zipCodeValue = $this.zipCodeInput.value;
        planCoverageData = [];
        SetShowCoverageResult(false, $this);
        SetPlanSelectEnabled(false, $this);
        SetSubmitButtonEnabled(false, $this);

        if (zipCodeValue.length < ZIP_CODE_MAX_LENGTH || !IS_ALL_DIGITS_REGEX.test(zipCodeValue)) {
            SetShowZipCodeError(true, $this);
        } else {
            if (event) event.target.blur();
            SetShowZipCodeError(false, $this);
            SetShowLoading(true, $this);
            $this.insurancePlanCombobox.value = "";

            const getInsuranceOptionsAwait = $this.onGetInsuranceOptions(zipCodeValue);
            const getInsuranceOptions = await getInsuranceOptionsAwait;

            let stateId;
            let cbsaId;

            if (!getInsuranceOptions) {
                SetShowZipCodeError(true, $this);
                SetShowLoading(false, $this);
                $this.insurancePlanCombobox.value = "";
            }
            if (getInsuranceOptions) {
                const cbsa = getInsuranceOptions.CBSA;
                cbsaId = cbsa.CBSAId;
            } else {
                const requestZipCodes = await $this.onRequestZipCodes();
                if (requestZipCodes) {
                    const zipCodeNumber = parseInt(zipCodeValue, 10);
                    const zips = requestZipCodes.Zips.filter(function(o) {
                        return o["ZipCode"] === zipCodeNumber;
                    });
                    if (!zips.length) {
                        return QUERY_EDGE_CASES.InvalidZip;
                    }
                    stateId = zips[0]["StateId"];
                }
            }

            const getCoverageData = await $this.onGetCoverageData(stateId, cbsaId);
            if (getCoverageData) {
                $this.onPopulatePlanSelect($this);
                SetPlanSelectEnabled(true, $this);
                $this.insurancePlanCombobox.focus();
            } else {
                SetShowError(true, $this);
            }

            SetShowLoading(false, $this);
        }
    }

    onFormSubmitListener(event) {
        event.preventDefault();
        const $this = this;
        const value = $this.insurancePlanSelect.value;
        const index = parseInt(value, 10);

        if (isNaN(index)) {
            SetShowCoverageResult(false, $this);
            return;
        }

        SetShowLoading(true, $this);

        setTimeout(function() {
            SetShowLoading(false, $this);
            SetShowCoverageResult(true, $this, planCoverageData, index);
        }, 1000);
    }

    onHandleFormEventListeners() {
        const $this = this;
        $this.form.addEventListener("submit", function(event) {
            $this.onFormSubmitListener(event);
        });

        $this.zipCodeInput.addEventListener("blur", event => {
            $this.onZipCodeSubmitListener(event);
        });

        $this.zipCodeInput.addEventListener("keydown", event => {
            if (event.key === "Enter" || event.key === "Tab") {
                $this.onZipCodeSubmitListener(event);
            }
        });

        $this.zipCodeSubmitButton.addEventListener("click", function() {
            $this.onZipCodeSubmitListener();
        });

        $this.zipCodeInput.addEventListener("input", function(event) {
            const target = event.target;
            const value = target.value;
            const ZIP_CODE_MAX_LENGTH = 5;
            if (value.length > ZIP_CODE_MAX_LENGTH) {
                target.value = value.substr(0, ZIP_CODE_MAX_LENGTH);
            }
        });
    }

    init() {
        const $this = this;
        GetAuthToken();
        $this.initCarousel();
        $this.initTooltip();
        $this.onHandleCoverageClick();
        $this.onHandleFormEventListeners();
        UpdateMonth($this.preferedDetailLastChild);
    }
}

export default CostCoverageTool;
