import Webinar from "./webinar";

export default class CoreEventCancellation extends Webinar {
    constructor() {
        super();
        if (this.isCancelComponent) {
            this.rsvpId = this.getParam("id");
            this.eventId = this.getParam("eventId");

            this.cancelEventContentElement = document.querySelector(".cancel-event-content");
            this.cancelBtn = document.querySelector("#cancel");

            return this.init();
        }
    }

    async loadEventDetail() {
        if (!this.accessToken) {
            this.accessToken = await this.getAccessToken();
        }

        if (!this.accessToken) {
            return;
        }

        const dataEvent = await this.getEventById(this.eventId);
        return this.fillEventDetail(dataEvent);
    }

    async onConfirmCancellation() {
        if (!this.rsvpId) {
            return;
        }

        if (!this.accessToken) {
            this.accessToken = await this.getAccessToken();
        }

        if (!this.accessToken) {
            return;
        }

        this.cancelEventContentElement.classList.add("hidden");
        const cancelEventHeader = document.querySelector(".cancel-event-header");
        if (cancelEventHeader) {
            cancelEventHeader.classList.add("hidden");
        }

        const cancelEventSuccess = document.querySelector(".cancel-event-success");
        if (cancelEventSuccess) {
            cancelEventSuccess.classList.remove("hidden");
        }

        const cancelEventSuccessHeader = document.querySelector(".cancel-event-success-header");
        if (cancelEventSuccessHeader) {
            cancelEventSuccessHeader.classList.remove("hidden");
        }

        const url = this.eventsApi + "rsvps/cancel/" + this.rsvpId;
        const headers = this.getDefaultHeaderContentUrlencoded();
        return fetch(url, {
            method: "PUT",
            cache: "no-cache",
            credentials: "same-origin",
            headers: headers,
            redirect: "follow",
            referrerPolicy: "no-referrer"
        })
            .then(response => response.json())
            .then(res => {
                cancelEventSuccess.classList.remove("loading");
                const cancelEventMessage = document.querySelector(".cancel-event-message");
                if (res.StatusCode != 200 && cancelEventMessage && res.Message) {
                    cancelEventMessage.innerText = res.Message;
                }
            })
            .catch(error => {
                console.error(error.message); // eslint-disable-line
                return;
            });
    }

    init() {
        const $this = this;

        if (!$this.eventId || !$this.rsvpId || !$this.cancelEventContentElement || !$this.cancelBtn) {
            return;
        }

        $this.cancelBtn.addEventListener("click", function() {
            return $this.onConfirmCancellation();
        });

        return $this.loadEventDetail();
    }
}
