/* eslint-disable no-undef */
import addListenerMulti from "../utils/addListenerMulti";

class HeroTextAnimate {
    constructor() {
        this.heroTextAnimate = document.querySelector(".inner-hero-video .text-cycle");
        if (!this.heroTextAnimate) return;
        this.words = this.heroTextAnimate.querySelectorAll(".word");
        this.init();
    }

    init() {
        if (videojs) {
            const bgVideo = document.querySelector(".video-js.bg-video");
            const video = videojs(bgVideo);
            video.play();
        }
        const $this = this;
        $this.heroTextAnimate.classList.add("playing");
        $this.words.forEach(node => {
            addListenerMulti(node, "webkitAnimationStart animationstart", () => {
                const itemActive = $this.heroTextAnimate.querySelector(".active");
                itemActive.classList.remove("active");
                node.classList.add("active");
                const widthWord = node.clientWidth;
                $this.heroTextAnimate.style.width = `${widthWord}px`;
            });
        });

        window.addEventListener("resize", () => {
            const itemActive = $this.heroTextAnimate.querySelector(".active");
            const widthWord = itemActive.clientWidth;
            $this.heroTextAnimate.style.width = `${widthWord}px`;
        });
    }
}

export default HeroTextAnimate;
