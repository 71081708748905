//strip trailing slashes from strings
function stripTrailingSlash(str) {
    if (str.substr(-1) === "/") {
        return str.substr(0, str.length - 1);
    }

    return str;
}

export default stripTrailingSlash;
