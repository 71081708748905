import ajaxRequest from "../../utils/ajaxRequest";
import setCookie from "../../utils/setCookie";
import getCookie from "../../utils/getCookie";
import { AUTH_HEADERS } from "./configParams";

const STORAGE_PREFIX = "IntouchDupixentCopayForm_";
const AUTH_TOKEN_KEY = "mmitAuthToken";

const onRequestToken = async () => {
    let url;
    const formularyElement = document.querySelector("[data-formulary-auth]");
    if (formularyElement) {
        url = formularyElement.getAttribute("data-formulary-auth");
    }
    const promiseObj = new Promise(resolve => {
        ajaxRequest({ url })
            .then(res => {
                const data = JSON.parse(res);
                resolve(data);
            })
            .catch(error => {
                resolve(error);
            });
    });
    return promiseObj;
};
export default async function GetAuthToken() {
    const tokenCookie = getCookie(STORAGE_PREFIX + AUTH_TOKEN_KEY);

    if (tokenCookie) {
        AUTH_HEADERS["Authorization"] += tokenCookie;
    } else {
        const data = await onRequestToken();
        if (data) {
            const token = data.accessToken;
            setCookie(STORAGE_PREFIX + AUTH_TOKEN_KEY, token, 1);
            AUTH_HEADERS["Authorization"] += token;
        }
    }
}
