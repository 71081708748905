import convertNodeListToArray from "./convertNodeListToArray";
export default function getSelectors(selector) {
    const _arr = typeof selector === "string" ? document.querySelectorAll(selector) : selector;

    let els = convertNodeListToArray(_arr);
    if (els.length === 0 && typeof selector !== "string") {
        els = [_arr];
    }

    return els;
}
