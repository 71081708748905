class BtnCollapsible {
    constructor() {
        this.collapsible = document.querySelectorAll("[data-collapsible]");
        this.init();
    }

    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    init() {
        const $this = this;
        if ($this.collapsible) {
            $this.collapsible.forEach(node => {
                const btn = node.querySelector(".collapsible__btn");
                btn.addEventListener("click", e => {
                    e.preventDefault();
                    node.classList.toggle("inner-show"); //class to help with accessibility
                    node.classList.toggle("js-active");
                });
            });
        }
        const indicationButtons = document.querySelectorAll("[data-indication]");
        if (indicationButtons) {
            indicationButtons.forEach(function(button) {
                button.addEventListener("click", function() {
                    $this.setCookie("indicationSelected", button.dataset.indication);
                    $this.setCookie("indicationLink", button.href);
                });
            });
        }
    }
}

export default BtnCollapsible;
