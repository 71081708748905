const indicationListDropdownBand = () => {
    let dropdownBandList = document.querySelectorAll(".indication-dropdown-band");
    if (dropdownBandList.length > 0) {
        let openState = false;
        dropdownBandList.forEach(band => {
            let btn = band.querySelector("#conditionListToggleBottom");
            let list = band.querySelectorAll(".conditionItemListBottom_menuItem");
            btn.addEventListener("click", e => {
                e.preventDefault();
                //ARIA
                let expand = btn.getAttribute("aria-expanded");
                if (expand === "false") {
                    btn.setAttribute("aria-expanded", "true");
                    list.forEach(l => {
                        l.setAttribute("aria-hidden", "false");
                    });
                } else if (expand == "true") {
                    btn.setAttribute("aria-expanded", "false");
                    list.forEach(l => {
                        l.setAttribute("aria-hidden", "true");
                    });
                }
                btn.classList.toggle("active");
                openState = !openState;
                $(".conditionListToggleMenu a")
                    .first()
                    .trigger("focus");
                menuLinkActive = 0;
            });
        });
        var menuLinks = $(".conditionListToggleMenu a");
        var menuLinksCount = menuLinks.length;
        let menuLinkActive = 0;

        document.addEventListener("keydown", function(e) {
            switch (e.code) {
                case "ArrowUp":
                    e.preventDefault();
                    menuLinkActive === 0 ? (menuLinkActive = menuLinksCount - 1) : menuLinkActive--;
                    break;
                case "ArrowDown":
                    e.preventDefault();
                    menuLinkActive === menuLinksCount - 1 ? (menuLinkActive = 0) : menuLinkActive++;
                    break;
                case "Escape":
                    $("#conditionListToggleBottom").removeClass("active");
                    break;
            }
            openState &&
                $(".conditionListToggleMenu a")
                    .eq(menuLinkActive)
                    .trigger("focus");
        });
    }
};

export default indicationListDropdownBand;
