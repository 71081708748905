import switchMailModalText from "./helpers/switchMailModalText";
import triggerISI from "./helpers/triggerIsi";
import progressBar from "./helpers/progressBar";
import scrollToElement from "../../utils/scrollToElement";
import removeParenthesisAndContent from "./helpers/removeParanthesis";
import DOMPurify from "dompurify";

/* -------------------
   BEGIN core-ddg-results
   Sitecore Resource: c35a39e1-5daa-4421-ac7f-d4d9e7f713da
      -------------------*/

function ddgResults() {
    $(".results-section").hide();
    $(".carousel-ddg-container").hide();
    switchMailModalText();
    //on last next control click show the final step
    $(".carousel-control.right").click(showFinalStep);

    // TODO: Add otions of parameters to access needed slides
    //if query params are present go to the final step

    if (window.location.search.includes("personal-info-parent")) {
        $(".landing-section").hide();
        updateResults();
    } else if ($(".carousel-ddg-container").hasClass("rollout-dtc-as-ddg-updates")) {
        $(".landing-section").hide();
        $(".carousel-ddg-container").show();
    } else {
        $(".landing-section").show();
        $(".section-progress-bar").hide();
    }

    $(".ddg-landing-btn").on("click", function(e) {
        e.preventDefault();
        $(".landing-section").hide();
        $(".section-progress-bar").show();
        $(".carousel-ddg-container").show();
        triggerISI();
        scrollToElement(".section-progress-bar");
    });

    //Back button on results page
    $("#back-to-carousel").click(function() {
        //make last-slide active
        $("#carousel-ddg .item").removeClass("active");
        $("#carousel-ddg .item:last-child").addClass("active");

        caourselControls("inline");

        $(".carousel-ddg-container").slideDown(600);
        $(".results-section").slideUp(600);

        //update top bar
        progressBar("impact");
    });

    $("#print-pdf").click(printPDF);
    $("#email-link-modal").on("hidden.bs.modal", function() {
        showAppointmentReminder();
    });

    $("#email-link").on("click", function() {
        showAppointmentReminder();
    });

    $("#restart-carousel").click(function() {
        //remove query params
        history.pushState(null, "", window.location.pathname);

        //go to 1st slide
        $("#carousel-ddg .item").removeClass("active");
        $("#carousel-ddg .item:first-child").addClass("active");

        $(".results-section").hide();
        $(".carousel-ddg-container").show();

        //reset form and update some form fields
        $("#main-form").trigger("reset");
        $(".form-group.range-slider-group").each(function() {
            $(this)
                .find('input[type="range"]')
                .removeClass("slidedThumb");
            $(this)
                .find(".slidecontainer")
                .attr("data-value", 0);
            $(this)
                .find(".range-output")
                .text("");
        });

        progressBar("personalInfo");

        //remove carousel controls on first slide
        caourselControls("none");

        //reset the progress bar circles
        $(".progress-bar-list")
            .children()
            .removeClass("completed");
    });
}

function showFinalStep() {
    if ($("#carousel-ddg .item:last").hasClass("active")) {
        var form = $("#main-form").serialize(),
            newURL;

        //newURL = '?' + encodeURIComponent(form) + '#appointment-reminder=false';
        newURL = "?" + encodeURIComponent(form) + "%26appointment-reminder%3Dfalse";
        history.pushState(null, "", newURL);

        updateResults();
    }
}

function caourselControls(control) {
    $("#carousel-ddg")
        .find(".left.carousel-control")
        .css("display", `${control}`);
    $("#carousel-ddg")
        .find(".right.carousel-control")
        .css("display", `${control}`);
}

function showAppointmentReminder() {
    var updatedURL = window.location.href.replace("false", "true");
    history.pushState(null, "", updatedURL);
    toggleAppointmentRemainder();
}

function toggleAppointmentRemainder() {
    var urlAppointment = window.location.href.indexOf("%26appointment-reminder%3Dtrue");
    if (urlAppointment > 0) {
        $(".appointment-reminder").slideDown();
    } else {
        $(".appointment-reminder").slideUp();
    }

    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent) || /iPhone\s(13)/i.test(navigator.userAgent)) {
        let timeElement = document.querySelector("#reminders-carousel .date-and-time .time-wrap .time-fields-container");
        timeElement.classList.add("safari-time");
    }
}

function updateResults() {
    var queryParam = decodeURIComponent(window.location.search);

    toggleAppointmentRemainder();
    $(".carousel-ddg-container").slideUp(600);
    $(".results-section").slideDown(600);

    var urlData = new URLSearchParams(queryParam),
        resultsItems = $(".results-section-box").find("[data-result]"),
        objResults = new Map();

    for (let x of urlData.keys()) {
        objResults.set(x, urlData.getAll(x));
    }
    for (let i = 0; i < resultsItems.length; i++) {
        var currentItem = $(resultsItems[i]),
            currentItemAttr = $(resultsItems[i]).data("result"),
            currentVal = objResults.get(currentItemAttr),
            valueStr = "";

        //checks for undefined and null
        if (currentVal == null) {
            continue;
        }

        let indication = document.querySelector(".results-section");

        if (currentItemAttr.indexOf("parent") > -1) {
            currentItem.removeClass().addClass(currentVal[0]);
        } else if (currentItemAttr.indexOf("range") > -1) {
            var colorsArr = ["#99cc00", "#fed900", "#f7a800", "#ff9933", "#7cc800"];

            if (currentItem.is("#disease-output")) {
                var currentli = currentItem.next("ul").children();
                currentli.removeClass();
                currentli[currentVal[0] - 1].classList.add("active");
            } else if (indication.classList && indication.classList.contains("eoe-ddg-updates") && currentItem.is(".think-about-condition-section span.indicator-val")) {
                currentItem.attr("style", `background-color:${colorsArr[4]} !important`);
            } else {
                let rangeContent = $(`.range-slider-group [name=${currentItemAttr}]`)
                    .next()
                    .children()[currentVal[0] - 1];

                if ($(rangeContent).find("span.dash").length) {
                    valueStr += $(`.range-slider-group [name=${currentItemAttr}]`)
                        .next()
                        .children()
                        [currentVal[0] - 1].textContent.trim()
                        .replace("-", "");
                } else {
                    valueStr += $(`.range-slider-group [name=${currentItemAttr}]`)
                        .next()
                        .children()
                        [currentVal[0] - 1].textContent.trim();
                }

                let truncatedString = valueStr.toLowerCase().replace(/\s/g, "");

                if (truncatedString == "notbothersomeatall") currentItem.attr("style", `background-color:${colorsArr[0]} !important`);
                else if (truncatedString == "slightlybothersome") currentItem.attr("style", `background-color:${colorsArr[2]} !important`);
                else if (truncatedString == "verybothersome") currentItem.attr("style", `background-color:${colorsArr[3]} !important`);
                else currentItem.attr("style", `background-color:${colorsArr[currentVal[0] - 1]} !important`);

                currentItem.text(valueStr);
            }
        } else if (currentItemAttr.indexOf("checkboxlist") > -1) {
            currentItem.children().remove();
            var filterArr = currentVal.filter((e, i, a) => a.indexOf(e) === i);
            let itemSet = new Set();

            $.each(filterArr, function(i) {
                let word = removeParenthesisAndContent(filterArr[i]);
                if (word.toLowerCase() === "proton pump inhibitors") word = filterArr[i];

                if (word.toLowerCase() == "eat slowly/chew excessively") word = "Eat slowly";

                //eslint-disable-next-line
                var li = $("<li/>")
                    .appendTo(currentItem)
                    .html(word);
                if (filterArr[i].toLowerCase() == "other" || filterArr[i].toLowerCase() == "otro") {
                    if (indication.classList.contains("eoe")) {
                        currentItem
                            .children()
                            .last()
                            .addClass("other-item")
                            .append("<hr>");
                    } else if (indication.classList.contains("pn")) {
                        if (currentItemAttr == "impact-3-checkbox-checkboxlist") {
                            currentItem
                                .children()
                                .last()
                                .addClass("other-item")
                                .append(`<hr><hr>`);
                        }
                    } else {
                        currentItem
                            .children()
                            .last()
                            .addClass("other-item")
                            .append(`<hr><hr><hr><hr><hr><hr>`);
                    }
                } else if (filterArr[i] == "None") {
                    if (filterArr.length > 1) {
                        currentItem
                            .children()
                            .last()
                            .css("display", "none");
                    }
                } else if (currentItemAttr == "impact-7-checkboxlist") {
                    let itemNo = 0;
                    let lcWord = word.toLowerCase();

                    if (lcWord == "improving lung function" || lcWord == "mejorar la función pulmonar") itemNo = 0;
                    else if (lcWord == "preventing severe asthma attacks" || lcWord == "prevenir ataques graves de asma") itemNo = 1;
                    else if (lcWord == "reducing or completely eliminating oral steroid use" || lcWord == "reducir o eliminar por completo el uso de esteroides orales") itemNo = 2;

                    itemSet.add("item" + itemNo);

                    currentItem
                        .children()
                        .last()
                        .addClass("item" + itemNo);
                }
            });
            if (indication && indication.classList.contains("eoe")) {
                let noRows = filterArr.length / 2 > 0 ? filterArr.length / 2 : 1;
                let grid = document.querySelector(".types-of-symptoms-section .types-of-symptoms");
                grid.style.gridTemplateRows = `repeat(${Math.ceil(noRows)},1fr`;
            }

            if (indication && indication.classList.contains("asthma")) {
                if (itemSet.size > 0) {
                    let grid = document.querySelector("#treatment-goal-section .treatment-goal-content");
                    grid.style.display = "grid";
                    if (itemSet.size == 1) {
                        grid.style.display = "flex";
                    } else if (containsItems(itemSet, ["item0", "item1"])) {
                        grid.style.gridTemplateAreas = '"item0 .""item1 ."';
                    } else if (containsItems(itemSet, ["item0", "item2"])) {
                        grid.style.gridTemplateAreas = '"item0 item2"';
                    } else if (containsItems(itemSet, ["item1", "item2"])) {
                        grid.style.gridTemplateAreas = '"item1 item2"';
                    } else if (containsItems(itemSet, ["item0", "item1", "item2"])) {
                        grid.style.gridTemplateAreas = '"item0 item2""item1 ."';
                    }
                }
            }
        } else if (currentItemAttr == "body") {
            $(".results-body .chkLabel").removeClass("chked");
            currentVal.map(function(val) {
                val = DOMPurify.sanitize(val);
                $(`.results-body input[value='${val}`)
                    .prop("checked", true)
                    .prop("disabled", true)
                    .parent()
                    .addClass("chked");
            });
        } else {
            //Filtering for duplicates in array
            var formattedArr = currentVal.filter((e, i, a) => a.indexOf(e) === i);

            valueStr = JSON.stringify(formattedArr);
            valueStr = valueStr
                .replaceAll('"', "")
                .replaceAll(",", ", ")
                .replace("[", "")
                .replace("]", "");
            currentItem.text(valueStr);
        }
    }
    updateFormFields(objResults);
}

function containsItems(set, items) {
    let flag = true;

    if (set.size != items.length) return false;

    for (let i = 0; i < items.length; i++) {
        if (!set.has(items[i])) flag = false;
    }

    return flag;
}

function updateFormFields(obj) {
    for (let [key, value] of obj.entries()) {
        key = DOMPurify.sanitize(key);
        var filterArr = value.filter((e, i, a) => a.indexOf(e) === i);
        filterArr.map(val => {
            val = DOMPurify.sanitize(val);
            if (key.indexOf("range") > -1) {
                $(`input[name='${key}']`)[0].value = val;
                $(`input[name='${key}']`)
                    .addClass("slidedThumb")
                    .trigger("click");
            } else {
                $(`input[name='${key}'][value='${val}'`).prop("checked", true);
            }
        });
    }

    $(".carousel-inner").attr("data-personal-info", $("input[name='personal-info-parent']:checked").val());

    progressBar("review");
}

function printPDF() {
    window.print();

    showAppointmentReminder();
}

export default ddgResults;
