function sliderFunct() {
    let sliders = document.querySelectorAll(".slidecontainer input[type=range]");
    let indication = document.getElementById("carousel-ddg");

    if (sliders.length > 0 && !indication.classList.contains("atopic")) {
        for (let slider of sliders) {
            if (slider.value == 1) slider.style.setProperty("--sliderRight", "-5px");
            else slider.style.setProperty("--sliderRight", "0px");

            slider.addEventListener("touchend", function() {
                slider.style.setProperty("--sliderRight", "0px");
            });

            slider.addEventListener("click", function() {
                slider.style.setProperty("--sliderRight", "0px");
            });
        }
    }
}

function selectOnlyNone() {
    let formGroups = document.querySelectorAll(".form-group.checkbox-group");

    if (formGroups.length > 1) {
        for (let formGroup of formGroups) {
            let checkLabels = formGroup.querySelectorAll(".chkLabel");

            // Deselect other options when none is selected
            let noneInput = null;

            if (formGroup.querySelector(`input[value="None"]`)) noneInput = formGroup.querySelector(`input[value="None"]`);
            else if (formGroup.querySelector(`input[value="Ninguno"]`)) noneInput = formGroup.querySelector(`input[value="Ninguno"]`);

            if (noneInput) {
                noneInput.addEventListener("click", () => {
                    for (let i = 0; i < checkLabels.length - 1; i++) {
                        checkLabels[i].querySelector("input").checked = false;
                    }
                });
            }

            // Deselect none when other options are selected
            for (let checkLabel of checkLabels) {
                let input = checkLabel.querySelector("input");
                let isNone = input.getAttribute("value").toLowerCase() == "none" || input.getAttribute("value").toLowerCase() == "ninguno";

                if (noneInput && !isNone) {
                    input.addEventListener("click", () => {
                        noneInput.checked = false;
                    });
                }
            }
        }
    }
}

function rangeListOption() {
    const rangeLists = document.querySelectorAll(".range-list");
    rangeLists.forEach(rangeList => {
        const firstChild = rangeList.querySelector(".range-opt:first-child");
        if (firstChild.innerHTML.includes("years")) {
            rangeList.classList.add("years");
        } else if (firstChild.innerHTML.toLowerCase().includes("days")) {
            rangeList.classList.add("years");
        } else if (firstChild.innerHTML.toLowerCase().includes("nunca")) {
            rangeList.classList.add("nunca");
        } else if (firstChild.innerHTML.split(" ").length === 1) {
            rangeList.classList.add(firstChild.innerHTML.toLowerCase());
        }
    });
}

rangeListOption();

export default { sliderFunct, selectOnlyNone };
