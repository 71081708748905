import DOMPurify from "dompurify";
var createCalendar = function(params) {
    if (!validParams(params)) {
        console.warn("Event details missing."); // eslint-disable-line
        return;
    }
    return generateMarkup(generateCalendars(params.data), getClass(params), getOrGenerateCalendarId(params));
};
var MS_IN_MINUTES = 60 * 1000;

var formatTime = function(date) {
    return date.toISOString().replace(/-|:|\.\d+/g, "");
};

var calculateEndTime = function(event) {
    return event.end ? formatTime(event.end) : formatTime(new Date(event.start.getTime() + event.duration * MS_IN_MINUTES));
};

var calendarGenerators = {
    google: function(event) {
        var startTime = formatTime(event.start);
        var endTime = calculateEndTime(event);

        var href = encodeURI(["https://www.google.com/calendar/render", "?action=TEMPLATE", "&text=" + (event.title || ""), "&dates=" + (startTime || ""), "/" + (endTime || ""), "&details=" + (event.description || ""), "&location=" + (event.address || ""), "&recur=" + (event.frequency || ""), "&sprop=&sprop=name:"].join(""));
        return '<a class="btn btn-primary google" target="_blank" href="' + href + '"><i class="fab fa-google"></i> Google</a>';
    },

    yahoo: function(event) {
        var eventDuration = event.end ? (event.end.getTime() - event.start.getTime()) / MS_IN_MINUTES : event.duration;

        // Yahoo dates are crazy, we need to convert the duration from minutes to hh:mm
        var yahooHourDuration = eventDuration < 600 ? "0" + Math.floor(eventDuration / 60) : Math.floor(eventDuration / 60) + "";

        var yahooMinuteDuration = eventDuration % 60 < 10 ? "0" + (eventDuration % 60) : (eventDuration % 60) + "";

        var yahooEventDuration = yahooHourDuration + yahooMinuteDuration;

        // Remove timezone from event time
        var st = formatTime(new Date(event.start - event.start.getTimezoneOffset() * MS_IN_MINUTES)) || "";

        var href = encodeURI(["http://calendar.yahoo.com/?v=60&view=d&type=20", "&title=" + (event.title || ""), "&st=" + st, "&dur=" + (yahooEventDuration || ""), "&desc=" + (event.description || ""), "&in_loc=" + (event.address || "")].join(""));

        return '<a class="btn btn-primary yahoo" target="_blank" href="' + href + '"><i class="fab fa-yahoo"></i> Yahoo!</a>';
    },

    ics: function(event, eClass, calendarName, iconClass) {
        var startTime = formatTime(event.start);
        var endTime = calculateEndTime(event);

        var href = [
            "BEGIN:VCALENDAR",
            "VERSION:2.0",
            "BEGIN:VEVENT",
            //'URL:' + document.URL,
            "DTSTART:" + (startTime || ""),
            event.frequency || "",
            "DTEND:" + (endTime || ""),
            "SUMMARY:" + (event.title || ""),
            "DESCRIPTION:" + (br2nl(event.description) || ""),
            "URL:" + (DOMPurify.sanitize(event.url) || ""),
            "LOCATION:" + (event.address || ""),
            "END:VEVENT",
            "END:VCALENDAR"
        ].join("\n");

        href = encodeURI("data:text/calendar;charset=utf8," + href);

        return '<a class="' + eClass + ' btn btn-primary" href="' + href + '" download="event.ics"><i class="fab ' + iconClass + '"></i> ' + calendarName + "</a>";
    },

    ical: function(event) {
        return this.ics(event, "icon-ical ical", '<span class="text-lowercase">i</span>Cal', "fa-apple");
    },

    outlook: function(event) {
        return this.ics(event, "icon-outlook outlook", "Outlook", "fa-windows");
    }
};
var generateCalendars = function(event) {
    return {
        google: calendarGenerators.google(event),
        ical: calendarGenerators.ical(event),
        outlook: calendarGenerators.outlook(event),
        yahoo: calendarGenerators.yahoo(event)
    };
};
var getClass = function(params) {
    if (params.options && params.options.class) {
        return params.options.class;
    }
};

var getOrGenerateCalendarId = function(params) {
    return params.options && params.options.id ? params.options.id : Math.floor(Math.random() * 1000000); // Generate a 6-digit random ID
};
// Make sure we have the necessary event data, such as start time and event duration
var validParams = function(params) {
    return params.data !== undefined && params.data.start !== undefined && (params.data.end !== undefined || params.data.duration !== undefined);
};

var generateMarkup = function(calendars, clazz, calendarId) {
    var result = document.createElement("span");

    //result.innerHTML = '<label for="checkbox-for-' + calendarId + '" class="add-to-calendar-checkbox">+ Add to my Calendar</label>';
    //result.innerHTML += '<input name="add-to-calendar-checkbox" class="add-to-calendar-checkbox" id="checkbox-for-' + calendarId + '" type="checkbox">';

    Object.keys(calendars).forEach(function(services) {
        result.innerHTML += calendars[services];
    });
    //result.className = 'calendar-btns';

    if (clazz !== undefined) {
        result.className += " " + clazz;
    }

    //addCSS();

    result.id = calendarId;
    return result;
};
function br2nl(str) {
    return str.replace(/<br\s*\/?>/gm, "\\n");
}
export default createCalendar;
