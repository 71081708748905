import { createPopper } from "@popperjs/core";

const smQuery = window.matchMedia("(max-width: 767px)");

function checkMedia() {
    return smQuery.matches;
}

const ddgTooltip = () => {
    let toggles = document.querySelectorAll(".ddg-tooltip-container");

    toggles.forEach(toggle => {
        let tooltip = toggle.nextElementSibling;
        clickOutsideElement(tooltip, () => {
            if (tooltip.dataset.show) {
                hide();
            }
        });

        const popperInstance = createPopper(toggle, tooltip, {
            modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: () => {
                            if (!checkMedia()) {
                                return [0, 8];
                            }

                            return [-110, 8];
                        }
                    }
                }
            ]
        });

        const show = () => {
            tooltip.setAttribute("data-show", true);
            popperInstance.update();
        };
        const hide = () => {
            tooltip.removeAttribute("data-show");
            popperInstance.update();
        };

        toggle.addEventListener("click", e => {
            e.preventDefault();
            e.stopPropagation();
            if (tooltip.dataset.show) {
                hide();
            } else {
                show();
            }
        });
        toggle.addEventListener("touchstart", e => {
            e.preventDefault();
            e.stopPropagation();
            if (tooltip.dataset.show) {
                hide();
            } else {
                show();
            }
        });
    });
};

function clickOutsideElement(element, cb) {
    document.addEventListener("click", event => {
        if (!element.contains(event.target)) cb();
    });
}

export default ddgTooltip;
