import createCookie from "../utils/createCookie";
import readCookie from "../utils/readCookie";

function init() {
    const indicationButtons = document.querySelectorAll("[data-cookie-indication]");

    indicationButtons.forEach(element => {
        const targetIndication = element.dataset.cookieIndication;
        const targetUrl = element.href;

        element.addEventListener(
            "click",
            function(e) {
                e.preventDefault();

                switch (targetIndication) {
                    case "atopicdermatitis":
                        createCookie("indication", "atopicdermatitis");
                        break;
                    case "asthma":
                        createCookie("indication", "asthma");
                        break;
                    case "crswnp":
                        createCookie("indication", "crswnp");
                        break;
                    case "eoe":
                        createCookie("indication", "eoe");
                        break;
                    case "prurigo-nodularis":
                    case "prurigo-nodularis-day15":
                    case "pn":
                        createCookie("indication", "prurigo-nodularis");
                        break;
                    case "copd":
                        createCookie("indication", "copd");
                        break;
                    case "support-savings":
                        if (!readCookie("indication")) createCookie("indication", "franchise");
                        break;
                    default:
                        createCookie("indication", "franchise");
                        break;
                }

                window.location = targetUrl;
            },
            false
        );
    });
}

export default { init };
