import DOMPurify from "dompurify";
/* -------------------
BEGIN core-glossary-tooltips 
Sitecore Resource: ac334625-08c7-4f06-b474-3bb68ffa62f1
-------------------*/
function init() {
    //activate opt-in bs tooltips
    $(function() {
        $("body").tooltip({
            selector: ".glossary-term",
            placement: "bottom",
            title: "",
            html: true,
            trigger: "hover focus",
            container: "body",
            template: '<div class="tooltip glossary-tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
        });

        $(".glossary-term").on("click", function(e) {
            e.preventDefault();
        });

        if ($(".glossary-term").length) getTerms();

        $(".glossary-term").on("keydown", function(e) {
            if (e.key === "Escape") {
                $(this).tooltip("hide");
            }
        });

        $(".glossary-term").each(function() {
            if (!$(this).attr("aria-roledescription")) $(this).attr("aria-roledescription", "tooltip");

            if (!$(this).attr("role")) $(this).attr("role", "tooltip");
        });
    });

    function convertDataToObject(data) {
        const parser = new DOMParser();
        const html = parser.parseFromString(data, "text/html");
        let glossaryList = html.body.querySelectorAll("ol.glossary-terms li ul li");

        let glossaryObject = {};

        glossaryList.forEach(item => {
            const itemInnerText = DOMPurify.sanitize(item.innerText);
            const itemValue = itemInnerText
                .trim()
                .split(`\n`)[1]
                .trim();
            glossaryObject[item.id.trim()] = itemValue;
        });
        return JSON.stringify(glossaryObject);
    }

    function getTerms() {
        if (typeof Storage !== "undefined" && sessionStorage[glossaryIndication]) {
            updateTerms(sessionStorage[glossaryIndication]);
        } else {
            if (!$(".glossary-term").attr("href")) {
                return;
            }

            $.ajax({
                url: $(".glossary-term").attr("href"),
                // eslint-disable-next-line no-unused-vars
                success: function(data, textStatus, jqXHR) {
                    let dataObject = convertDataToObject(data);
                    sessionStorage[glossaryIndication] = dataObject;
                    updateTerms(sessionStorage[glossaryIndication]);
                }
            });
        }
    }

    function updateTerms(data) {
        $(".glossary-term").each(function() {
            if ($(this).attr("href")) {
                var id = $(this)
                    .attr("href")
                    .split("#")[1];
                if (id.length < 2) return false;
                let glossaryContent = JSON.parse(data)[id];
                $(this).attr("data-original-title", glossaryContent);
            }
        });
    }

    let glossaryIndication = "franchise-glossary";
    if (window.location.href.includes("atopicdermatitis")) {
        glossaryIndication = "atopicdermatitis-glossary";
    } else if (window.location.href.includes("asthma")) {
        glossaryIndication = "asthma-glossary";
    } else {
        glossaryIndication = "crswnp-glossary";
    }
}

export default { init };
