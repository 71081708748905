function switchMailModalText() {
    let mailModalText = document.querySelector(".email-modal .form-content p");
    let textOverride = document.querySelector("[data-customMailModalText] p");
    if (textOverride) {
        textOverride = textOverride.innerText;
        if (textOverride.length > 0) {
            mailModalText.innerText = textOverride;
            mailModalText.style.fontWeight = "bold";
        }
    }
}

export default switchMailModalText;
