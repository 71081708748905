/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
class FilterGalleryForm {
    constructor() {
        this.init();
    }

    handleChapters(targetVideoWrapper) {
        function updateChapters(player) {
            var currentTime = player.currentTime();

            (this.el, this.startTime <= currentTime && currentTime < this.endTime) ? this.el.addClass("active") : this.el.removeClass("active");
        }

        function handleChapterChange(player) {
            player.currentTime($(this).data("time"));
        }

        targetVideoWrapper.each(function() {
            var player = videojs(this),
                chapters = $($(this).data("chapters-container"));

            //check for chapters container next to video
            if (!chapters.length) return false;

            player.on("loadedmetadata", function() {
                var tracks = player.remoteTextTracks()[0];

                //empty chapters container
                chapters.html("");

                //check if metadata type is chapters
                if (tracks.kind !== "chapters") {
                    $(".chapters__tab").hide();
                    $(".transcript__tab a").tab("show");
                    this.removeClass("hasChapters");
                    return false;
                } else {
                    this.addClass("hasChapters");
                    $(".chapters__tab").show();
                    $(".chapters__tab a").tab("show");
                }

                //loop through chapter metadata
                for (var i = 0; i < tracks.cues.length; i++) {
                    var cue = tracks.cues[i],
                        $el = $('<li><span class="start-time">' + videojs.formatTime(cue.startTime, "M:SS") + "</span> " + cue.text + "</li>");

                    //create chapter elements
                    $el.data("time", cue.startTime)
                        .on("click", $.proxy(handleChapterChange, $el, player))
                        .appendTo(chapters);

                    cue.el = $el;

                    tracks.addEventListener("cuechange", $.proxy(updateChapters, cue, player));
                }
            });
        });
    }

    init() {
        var $this = this;
        var $form = $("#filter-form"),
            $gallery = $(".carousel-inner"),
            $carousel = $("#video-gallery"),
            $prev = $(".controls-wrap .prev"),
            $next = $(".controls-wrap .next");

        $form
            .on("input reset", function(e) {
                setTimeout(function() {
                    var arr = $form.serializeArray(),
                        category = "",
                        selectors = "",
                        $items = $(".gallery-item"),
                        itemsPerPage = $(window).width() <= 768 ? 4 : 12,
                        totalItems = $items.length,
                        pages = Math.ceil(totalItems / itemsPerPage),
                        keywordStr = $("#keyword-search").length
                            ? $("#keyword-search").val().length > 2
                                ? $("#keyword-search")
                                      .val()
                                      .toLowerCase()
                                : ""
                            : null;

                    $items.appendTo(".gallery-items");
                    $gallery.html("");
                    $(".carousel-indicators").html("");

                    //filter items
                    for (var index = 0; index < arr.length; index++) {
                        if (selectors !== "") selectors += ",";
                        selectors += `[data-tags*='${arr[index].name}:${arr[index].value}']`;

                        if (index === arr.length - 1 || arr[index + 1].name !== arr[index].name) {
                            $items = $items.filter(selectors);
                            selectors = "";
                        }
                        category = arr[index].name;
                    }

                    $items = $items.filter(function(index, item) {
                        keywordStr ? item.innerText.toLowerCase().indexOf(keywordStr) > -1 : $items;
                    });

                    //update page item count
                    $(".item-count").text($items.length + " items");

                    //create pages of items
                    for (var i = 0; i < pages; i++) {
                        $items.slice(i * itemsPerPage, (i + 1) * itemsPerPage).wrapAll('<div class="item">');
                        $(".carousel-indicators").append('<li data-target="#video-gallery" data-slide-to="' + i + '"></li>');
                    }

                    //activate first page
                    $(".gallery-items .item")
                        .appendTo(".carousel-inner")
                        .eq(0)
                        .addClass("active");
                    $(".carousel-indicators li:first-child").addClass("active");

                    //configure nav
                    if ($("#video-gallery .item").length > 0) {
                        $next.removeClass("invisible");
                        $(".controls-wrap").removeClass("invisible");
                    } else {
                        $(".controls-wrap").addClass("invisible");
                    }
                }, 0);
            })
            .trigger("reset");

        $carousel
            .on("slide.bs.carousel", function(e) {
                var activeList = $(e.relatedTarget),
                    index = activeList.index(),
                    length = $("#video-gallery .item").length;

                index === 0 ? $prev.addClass("invisible") : $prev.removeClass("invisible"); //first question
                index === length - 1 ? $next.addClass("invisible") : $next.removeClass("invisible");

                setTimeout(function() {
                    $(".carousel-indicators li")
                        .removeClass("active")
                        .filter(":nth-child(" + (index + 1) + ")")
                        .addClass("active");
                }, 1);

                if ($(window).width() < 768) scrollToEl("#video-gallery");
            })
            .on("slid.bs.carousel", function(e) {});

        $("#webinar-video-modal")
            .on("show.bs.modal", function(e) {
                let findtarget = $(this).find(".video-js");

                $this.handleChapters(findtarget);

                var videoId = $(e.relatedTarget).data("video-id"),
                    player = videojs($(this).find(".video-js")[0]);

                player.catalog.getVideo(videoId, function(error, video) {
                    player.catalog.load(video);
                });

                let hasTranscript = false;
                let transcriptHtml = $(".video_transcript.hidden[data-video-id='" + videoId + "']").html();

                if (transcriptHtml.length > 0) {
                    hasTranscript = true;
                    let pane = $("#webinar-video-modal #video-transcript .pane-wrapper");

                    pane.append(transcriptHtml);
                } else {
                    hasTranscript = false;
                    $("#webinar-video-modal #video-transcript .pane-wrapper").empty();
                }

                $(".modal-footer").fadeIn(1200);
            })
            .on("hide.bs.modal", function(e) {
                let player = videojs($(this).find(".video-js")[0]);

                player.pause();
                player.reset();
                $(".modal-footer").hide();
            });

        $(".pane-wrapper").on("scroll", function(ev) {
            this.scrollTop + this.offsetHeight + 1 >= this.scrollHeight
                ? $(this)
                      .parent()
                      .addClass("scroll-to-bottom")
                : $(this)
                      .parent()
                      .removeClass("scroll-to-bottom");
        });
        if (window.innerWidth < 768) {
            var filterSections = Array.from(document.querySelectorAll("#filter-form .collapse"));
            var filterSectionButtons = Array.from(document.querySelectorAll("#filter-form .filter-collapse-toggle"));

            filterSections.forEach(node => node.classList.remove("in"));
            filterSectionButtons.forEach(node => node.classList.add("collapsed"));
        }
    }
}

export default FilterGalleryForm;
