import FormValidation from "../plugins/validate";
import Webinar from "./webinar";
import BaseForm from "./baseForm";
import getOffset from "../utils/getOffset";
import { load } from "recaptcha-v3";

export default class CoreEventRegistration extends Webinar {
    constructor() {
        super();
        this.formValidationQueryString = "[data-webinar-registration-form]";
        this.form = document.querySelector("[data-webinar-registration-form]");
        if (this.form) {
            this.fieldsValidateByApi = this.form.querySelectorAll("[data-api]");
            this.baseForm = new BaseForm(document.querySelector(".box-event-reg"));
            this.accessToken = null;
            this.init();
        }
    }

    onValidateForm() {
        const $this = this;
        if ($this.form) {
            new FormValidation({
                selector: this.formValidationQueryString,
                submitForm: false,
                invalidScroll: true,
                events: {
                    doSuccess() {
                        var recaptchaV3String = $("button[name='recaptcha']").val();
                        var recaptchaV3Boolean = recaptchaV3String === "true";
                        if (recaptchaV3Boolean) {
                            const recaptchaID = document.querySelector(".submit").dataset.sitekey;
                            load(recaptchaID).then(recaptcha => {
                                recaptcha.execute("submit").then(async token => {
                                    const winOrigin = window.location.origin;
                                    this.baseEndPtUrl = winOrigin;
                                    let url = this.baseEndPtUrl + "/.rest/webinarRecaptcha/";
                                    let data = "g-recaptcha-response=" + token;
                                    const response = await fetch(url, {
                                        method: "POST",
                                        cache: "no-cache",
                                        credentials: "same-origin",
                                        headers: { "Content-Type": "application/x-www-form-urlencoded" },
                                        redirect: "follow",
                                        referrerPolicy: "no-referrer",
                                        body: data
                                    });

                                    if (response.ok) {
                                        if ($this.form.querySelectorAll("input.invalid").length == 0) {
                                            $this.onSubmitWebinar();
                                        }
                                    }
                                    return false;
                                });
                            });
                        } else {
                            if ($this.form.querySelectorAll("input.invalid").length == 0) {
                                $this.onSubmitWebinar();
                            }
                        }
                    },
                    doError() {}
                }
            });
        }
    }

    onClickNext() {
        this.next.addEventListener("click", () => {
            const currentStepGroup = this.form.querySelector(".dupixent-form .item.active");
            const currentStepInputElements = currentStepGroup.getElementsByTagName("input");
            for (let i = 0; i < currentStepInputElements.length; i++) {
                if (currentStepInputElements[i].classList.contains("invalid")) {
                    continue;
                }
                currentStepInputElements[i].focus();
                currentStepInputElements[i].blur();
            }

            const existError = currentStepGroup.getElementsByClassName("invalid");
            if (existError.length > 0) {
                return;
            }

            const currentIndicator = this.form.querySelector(".carousel-indicators li.active");
            const nextIndicator = this.form.querySelector(".carousel-indicators li:not(.active)");

            this.next.classList.add("hidden");
            this.submit.classList.remove("hidden");
            this.prev.classList.remove("invisible");
            const nextStepGroup = this.form.querySelector(".dupixent-form .item:not(.active)");
            currentStepGroup.classList.remove("active");
            currentIndicator.classList.remove("active");
            currentStepGroup.classList.add("answered");
            currentIndicator.classList.add("answered");
            nextStepGroup.classList.add("active");
            nextIndicator.classList.add("active");
        });
    }

    onClickPrev() {
        this.prev.addEventListener("click", () => {
            this.next.classList.remove("hidden");
            this.submit.classList.add("hidden");
            this.prev.classList.add("invisible");

            const currentIndicator = this.form.querySelector(".carousel-indicators li.active");
            const prevIndicator = this.form.querySelector(".carousel-indicators li:not(.active)");

            const currentStepGroup = this.form.querySelector(".dupixent-form .item.active");
            const prevStepGroup = this.form.querySelector(".dupixent-form .item:not(.active)");
            currentStepGroup.classList.remove("active");
            currentIndicator.classList.remove("active");
            prevStepGroup.classList.remove("answered");
            prevIndicator.classList.remove("answered");
            prevStepGroup.classList.add("active");
            prevIndicator.classList.add("active");
        });
    }

    onPhoneOptionChange() {
        const textOptionElement = this.form.querySelector("#text-option");
        const phoneOptionElement = this.form.querySelector("#phone-option");
        const phoneNumberOptionElement = this.form.querySelector("#phone-number-option");
        const phoneNumberInputElement = this.form.querySelector("#phone-number");
        if (!textOptionElement || !phoneOptionElement || !phoneNumberOptionElement || !phoneNumberInputElement) {
            return;
        }

        textOptionElement.addEventListener("change", e => {
            e.preventDefault();
            if (e.target.checked) {
                phoneNumberOptionElement.classList.remove("hidden");
                if (phoneNumberInputElement.value.length !== 0) {
                    phoneNumberInputElement.value = "";
                }
            } else if (!phoneOptionElement.checked) {
                phoneNumberOptionElement.classList.add("hidden");
                phoneNumberInputElement.value = "0000000000";
            }
        });

        phoneOptionElement.addEventListener("change", e => {
            e.preventDefault();
            if (e.target.checked) {
                phoneNumberOptionElement.classList.remove("hidden");
                if (phoneNumberInputElement.value.length !== 0) {
                    phoneNumberInputElement.value = "";
                }
            } else if (!textOptionElement.checked) {
                phoneNumberOptionElement.classList.add("hidden");
                phoneNumberInputElement.value = "0000000000";
            }
        });
    }

    onMarketingChange() {
        const marketingOptElement = this.form.querySelector("#marketing");
        const termsElement = this.form.querySelector("#terms");
        if (!marketingOptElement || !termsElement) {
            return;
        }

        marketingOptElement.addEventListener("change", e => {
            e.preventDefault();
            if (e.target.checked) {
                termsElement.classList.remove("hidden");
            } else {
                termsElement.classList.add("hidden");
            }
        });
    }

    async onSubmitWebinar() {
        const boxEventRegister = document.querySelector(".box-event-reg");
        if (boxEventRegister) {
            boxEventRegister.classList.add("loading");
        }

        if (!this.eventId) {
            return;
        }

        if (!this.accessToken) {
            this.accessToken = await this.getAccessToken();
        }

        if (!this.accessToken) {
            return;
        }

        let body = this.baseForm.serializeFormData();
        if (body.includes("PhoneNumber=0000000000")) {
            body = body.replace("PhoneNumber=0000000000", "PhoneNumber=");
        }
        if (!body.includes("ReminderBySms")) {
            body += "&ReminderBySms=false";
        }

        if (!body.includes("ReminderByPhone")) {
            body += "&ReminderByPhone=false";
        }

        if (!body.includes("MarketingOptIn")) {
            body += "&MarketingOptIn=false";
        }

        const url = this.eventsApi + "rsvps";
        const headers = this.getDefaultHeaderContentUrlencoded();

        return fetch(url, {
            method: "POST",
            cache: "no-cache",
            credentials: "same-origin",
            headers: headers,
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body
        })
            .then(response => response.json())
            .then(res => {
                this.handleRegisterResult(res);
                return boxEventRegister.classList.remove("loading");
            })
            .catch(error => {
                console.error(error.message); // eslint-disable-line
                return boxEventRegister.classList.remove("loading");
            });
    }

    handleRegisterResult(res) {
        try {
            this.form.classList.add("hidden");
            if (res.Result.Success) {
                const registerSuccessElement = document.querySelector(".register-success");
                window.scrollTo({
                    top: getOffset(registerSuccessElement),
                    behavior: "smooth"
                });
                registerSuccessElement.classList.remove("hidden");
            } else if (res.Result.WaitList) {
                const waitListSuccessElement = document.querySelector(".waitlist-success");
                window.scrollTo({
                    top: getOffset(waitListSuccessElement),
                    behavior: "smooth"
                });
                waitListSuccessElement.classList.remove("hidden");
            }
        } catch (e) {
            console.error(e.message); // eslint-disable-line
            return;
        }
    }

    handleCheckEmailRegistered() {
        const emailAddress = this.form.querySelector("#email-address");
        if (emailAddress) {
            emailAddress.addEventListener("blur", async e => {
                e.preventDefault();
                if (!this.accessToken) {
                    this.accessToken = await this.getAccessToken();
                }

                if (!this.accessToken) {
                    return;
                }

                const isEmailRegistered = await this.isEmailRegistered(emailAddress.value);
                setTimeout(this.handleEmailRegisteredValidation(emailAddress, isEmailRegistered), 10000);
            });
        }
    }

    isEmailRegistered(emailAddress) {
        if (!this.accessToken) {
            return false;
        }

        if (!this.eventId) {
            return false;
        }

        if (!emailAddress) {
            return false;
        }

        if (emailAddress.length == 0) {
            return false;
        }

        const url = this.eventsApi + "events/" + this.eventId + "/rsvps/" + emailAddress;
        const headers = this.getDefaultHeaderContentJson();
        return fetch(url, {
            method: "POST",
            cache: "no-cache",
            credentials: "same-origin",
            headers: headers,
            redirect: "follow",
            referrerPolicy: "no-referrer"
        })
            .then(response => response.json())
            .then(data => {
                return data.isRegistered == true;
            })
            .catch(error => {
                console.error(error.message); // eslint-disable-line
                return false;
            });
    }

    handleEmailRegisteredValidation(emailAddress, isEmailRegistered) {
        if (emailAddress.classList.contains("invalid")) {
            return;
        }
        if (isEmailRegistered) {
            const dataValidateId = emailAddress.getAttribute("data-validate-id");
            const errEmailElm = '<div id="' + dataValidateId + '" class="error error-container">This email address is already registered for this event.</div>';
            if (emailAddress.parentElement.querySelector(".error")) {
                emailAddress.parentElement.querySelector(".error").remove();
            }
            emailAddress.classList.add("invalid");
            emailAddress.parentElement.insertAdjacentHTML("beforeend", errEmailElm);
        } else if (emailAddress.parentElement.querySelector(".error")) {
            emailAddress.classList.remove("invalid");
            emailAddress.parentElement.querySelector(".error").remove();
        }
    }

    init() {
        if (!this.form) {
            return;
        }

        const dataEventId = this.form.querySelector("[data-event-id]");
        if (dataEventId) {
            dataEventId.value = this.eventId;
        }

        this.prev = this.form.querySelector(".prev");
        this.next = this.form.querySelector(".next");
        this.submit = this.form.querySelector(".submit");

        this.phoneNumberInputElement = this.form.querySelector("#phone-number");
        this.phoneNumberInputElement.value = "0000000000"; // 10 zeros

        this.onValidateForm();

        this.onClickNext();
        this.onClickPrev();
        if (!localStorage.getItem("noSafeToSend")) {
            this.handleCheckEmailRegistered();
        }
        this.onPhoneOptionChange();
        this.onMarketingChange();
        this.form.querySelector(".submit").addEventListener("click", () => {
            if (document.getElementById("phone-number").value == "0000000000") {
                this.phoneNumberInputElement.classList.remove("invalid");
            }
        });
    }
}
