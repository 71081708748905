export default class References {
    constructor() {
        this.listReferences = document.querySelector(".section-references ol");
        this.init();
    }

    init() {
        const $this = this;
        if ($this.listReferences) {
            $this.listReferences.classList.add("list-references");
        }
        $(".section-references").insertAfter(".isi.sticky");
    }
}
