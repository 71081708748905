const flipToggleSingle = () => {
    $(".flip-container .back").attr("inert", "");

    $(".flip-toggle").on("click", function(e) {
        e.preventDefault();

        const toggle = $(this);
        const container = toggle.parents(".flip-container");
        const front = container.find(".front");
        const back = container.find(".back");

        container.toggleClass("active");
        if (container.hasClass("active")) {
            front.attr("inert", "");
            back.removeAttr("inert");
        } else {
            front.removeAttr("inert");
            back.attr("inert", "");
        }
    });
};

export default flipToggleSingle;
