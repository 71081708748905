import { sanitize } from "dompurify";
import scrollToElement from "../utils/scrollToElement";
import onScrollTranscript from "../utils/onScrollTranscript";

function renderArrowCarousel(control) {
    if (!control) return;
    var textControl = control.split("|");
    return "<div class='text-component controls-wrap hidden-sm hidden-md hidden-lg'>" + "<a href='#carousel' class='btn btn-link btn-prev invisible' role='button' data-slide='prev'><span class='fa fa-arrow-left' aria-hidden='true'></span>" + textControl[0] + "</a>" + "<a href='#carousel' class='btn btn-link btn-next' role='button' data-slide='next'>" + textControl[1] + "<span class='fa fa-arrow-right' aria-hidden='true'></span></a>" + "</div>";
}

function renderAmbassadorContentMobile() {
    var galleryChild = $(".ambassador-section.gallery-wrapper");
    var ambassadorRowChild = $(".ambassador-row").children();
    if (!galleryChild.length && !ambassadorRowChild.length) {
        return;
    }
    galleryChild[0].classList.add("active");
    for (var i = 0, len = galleryChild.length; i < len; i++) {
        var listAmbassadorRowChild = $(ambassadorRowChild[i]),
            listGalleryVideo = $(galleryChild[i]),
            cloneContent = listAmbassadorRowChild.clone(),
            content = cloneContent
                .find("a.ambassador-link")
                .remove()
                .end()
                .addClass("visible-xs");
        // set content to gallery on Mobile
        $(content).insertAfter(listGalleryVideo.find("h2"));
        // get ID Gallery video
        var idGallery = listGalleryVideo.attr("id");
        listAmbassadorRowChild.find(".ambassador-link").attr("href", "#" + idGallery);
    }
}

function addClassAmbassadorWrapper() {
    var idAmbassador = $("#meet-ambassadors");
    if (idAmbassador.length) {
        var scoreContainer = idAmbassador.closest(".score-container");
        scoreContainer.addClass("meet-ambassadors-wrap");
    }
}

function init() {
    //grabing hash
    const hash = new URL(sanitize(window.location.href)).hash;
    var dataCarousel = $("[data-carousel-gallery]"),
        dataCarouselControl = dataCarousel.length ? dataCarousel.data("carouselControl") : "",
        arrowElement = renderArrowCarousel(dataCarouselControl),
        scoreInner = dataCarousel.find(".score-inner");
    renderAmbassadorContentMobile();
    addClassAmbassadorWrapper();

    if (scoreInner.children().length <= 1) return;

    dataCarousel.find(".score-main").append(arrowElement);

    dataCarousel
        .find(".score-main")
        .addClass("carousel")
        .attr("id", "carousel")
        .carousel({
            interval: false,
            wrap: false,
            keyboard: false
        })
        .on("slide.bs.carousel", handleAmbassadorChange)
        .find(".score-inner")
        .addClass("carousel-inner");

    $(".ambassador-link").on("click", function(e) {
        e.preventDefault();
        scrollToElement($(this).attr("href"));
    });
    var initialSlide = 0;
    if (hash) {
        // Check if on mobile
        if ($(window).width() < 768 && dataCarousel.length) {
            // Set starting slide

            let item = document.querySelector(hash);

            if (item) {
                initialSlide = Array.from(item.parentNode.children).indexOf(item);
            } else {
                initialSlide = 0;
            }

            dataCarousel.find(".score-main").carousel(initialSlide);
            setTimeout(function() {
                scrollToElement(".carousel-pagination");
            }, 500);
        }
    }
}

onScrollTranscript(".tab-pane.overlay .pane-wrapper");
onScrollTranscript(".tab-pane.transcripts .tab-content");

function handleAmbassadorChange(e) {
    var activeItem = $(e.relatedTarget),
        index = activeItem.index(),
        prev = $(".btn-prev"),
        next = $(".btn-next"),
        pagers = $(".carousel-pagination li");
    index === 0 ? prev.addClass("invisible") : prev.removeClass("invisible"); //first question
    index === $(".carousel-pagination li").length - 1 ? next.addClass("invisible") : next.removeClass("invisible"); //last question
    pagers
        .removeClass("active")
        .eq(index)
        .addClass("active");
    if ($(".carousel-pagination").length) {
        scrollToElement(".carousel-pagination");
    }
}

export default { init };
