let scrollGradient = () => {
    return {
        hideGradient: false,
        init() {
            // if there isn't any scroll - automatically hides gradient
            setTimeout(() => {
                const panel = document.querySelector(".loadClass");
                if (panel.scrollHeight > panel.clientHeight) {
                    this.hideGradient = true;
                }
            }, 500);
        },
        detectScrollEnd() {
            this.hideGradient = this.$el.scrollTop >= this.$el.scrollHeight - this.$el.offsetHeight - 0.5;
        }
    };
};

export default scrollGradient;
