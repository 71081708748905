export default function onScrollTranscript(element) {
    $(element).on("scroll", function() {
        this.scrollTop + this.offsetHeight + 1 >= this.scrollHeight
            ? $(this)
                  .parent()
                  .addClass("scroll-to-bottom")
            : $(this)
                  .parent()
                  .removeClass("scroll-to-bottom");
    });
}
onScrollTranscript(".transcript .inner");
