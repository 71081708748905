import getParameterByName from "../utils/getParameterByName";
import createCookie from "../utils/createCookie";
function init() {
    const mocCookieName = "QueryString__MOC";
    let mocCodeDash = getParameterByName("moc-code");
    let mocCode = getParameterByName("moccode");
    let mocCodeCamel = getParameterByName("mocCode");
    let mocUppercase = getParameterByName("MOC");
    let mocLowercase = getParameterByName("moc");

    if (mocCodeDash) {
        createCookie(mocCookieName, mocCodeDash);
    }
    if (mocCode) {
        createCookie(mocCookieName, mocCode);
    }
    if (mocCodeCamel) {
        createCookie(mocCookieName, mocCodeCamel);
    }
    if (mocUppercase) {
        createCookie(mocCookieName, mocUppercase);
    }
    if (mocLowercase) {
        createCookie(mocCookieName, mocLowercase);
    }
}
export default { init };
