import ddgCore from "./ddgCore";
import ddgResults from "./ddgResults";
import ddgAppointmentReminders from "./ddgAppointmentReminders";

function ddgInit() {
    if (document.querySelector(".doctor-discussion-guide")) {
        ddgCore();
        ddgResults();
        ddgAppointmentReminders();
    }
}

export default ddgInit;
