import { CountUp } from "../plugins/CountUp";

class chart {
    constructor() {
        this.init();
    }

    init() {
        $(".collapse")
            .on("shown.bs.collapse", function() {
                $(this)
                    .find(".bar")
                    .each(function(index, el) {
                        $(el).css("height", $(el).data("value") + "%");
                        var perctext = new CountUp($(el).find(".value")[0], $(el).data("value"));
                        if (!perctext.error) {
                            perctext.start();
                        } else {
                            console.error(perctext.error); // eslint-disable-line
                        }
                    });
            })
            .on("hidden.bs.collapse", function() {
                $(this)
                    .find(".bar")
                    .css("height", "0px");
                $(this)
                    .find(".value")
                    .html("");
            });
    }
}

export default chart;
