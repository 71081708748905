import getParameterByName from "./queryParams";

class WebinarForm {
    constructor() {
        this.formElement = document.getElementById("event-reg");
        if (this.formElement) {
            this.searchArray = ["moc-code", "moccode", "mocCode", "MOC", "moc"];
            this.init();
        }
    }
    init() {
        this.searchArray.map(param => {
            getParameterByName(param) ? this.addHiddenField("MediaOriginCode", getParameterByName(param)) : "";
        });
    }
    addHiddenField(name, val) {
        let input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", name);
        input.setAttribute("value", val);

        this.formElement.appendChild(input);
    }
}

export default WebinarForm;
