export default function createHiddenField(name, val, formElement) {
    let input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", name);
    input.setAttribute("value", val);

    if (formElement) {
        formElement.appendChild(input);
    }
}
