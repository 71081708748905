const coreStickyHeader = function() {
    var $window = $(window),
        $body = $("body"),
        startPosition = $window.scrollTop(),
        prevScrollDirection = null,
        prevScrollPos = 99999,
        threshold = 500;

    $window.on("resize scroll orientationchange", function() {
        var distSticky = $(".pageWrapper").offset().top,
            scrollTop = $window.scrollTop(),
            scrollDirection = prevScrollPos < scrollTop ? "down" : "up",
            distScrolled = Math.abs(startPosition - scrollTop);

        prevScrollPos = scrollTop;

        //determines if scroll direction changed since last event fire
        if (scrollDirection !== prevScrollDirection) {
            prevScrollDirection = scrollDirection;
            startPosition = $window.scrollTop();
            distScrolled = 0;
        }

        //conditional to hide or show the header
        if (scrollDirection == "down") {
            //DOWN
            if (scrollTop >= distSticky + $("header").outerHeight()) {
                //check to make sure the header has passed the sticky distance
                $body.addClass("header-hide");
            }
        } else {
            //UP
            if (scrollTop >= distSticky + $("header").outerHeight() && distScrolled > threshold) {
                //check to make sure the header has passed the sticky
                $body.addClass("header-sticky").removeClass("header-hide");
            } else if ($body.hasClass("header-hide") && scrollTop < distSticky + $("header").outerHeight()) {
                $body.removeClass("header-hide header-sticky");
            }

            if (scrollTop < distSticky) {
                $body.removeClass("header-sticky");
            }
        }
    });
};

export default coreStickyHeader;
