import getCookie from "../utils/getCookie";
import createHiddenField from "../utils/createHiddenField";

let recaptchaValid = false;
function formChange() {
    let valid = true;
    $("[required]").each(function() {
        if ($(this).is(":invalid") || !$(this).val()) valid = false;
    });
    if (valid && recaptchaValid) {
        $("#isi").addClass("hidden");
        $(".dupixent-indicated").addClass("hidden");
        $(".submit").removeClass("disabled");
        $(".submit").addClass("btnEnabled");
        $(".submit").css("pointer-events", "");
    } else {
        $(".submit").addClass("disabled");
        $(".submit").removeClass("btnEnabled");
        $(".submit").css("pointer-events", "none");
    }
}

function recaptchaCallback() {
    recaptchaValid = true;
    formChange();
}

function recaptchaExpired() {
    recaptchaValid = false;
    formChange();
}

function init() {
    window.recaptchaCallback = recaptchaCallback;
    window.recaptchaExpired = recaptchaExpired;
    const formElement = document.getElementById("dmwEmailSignUp");
    getCookie("QueryString__MOC") ? createHiddenField("mediaOriginCode", getCookie("QueryString__MOC"), formElement) : "";

    $(function() {
        $("#dmwEmailSignUp").tooltip({
            selector: ".info",
            placement: "top",
            title: "",
            html: true,
            trigger: "click",
            container: "body",
            template: '<div class="tooltip dmwEmailSignUpForm" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
        });

        $("#dmwEmailSignUp").on("change", () => formChange());

        $(document).ready(function() {
            $(".info").each(function() {
                const el = this;
                $(el).on("inserted.bs.tooltip", function() {
                    $(".btn-ok").on("click", function() {
                        $(el).tooltip("hide");
                    });
                });
            });
        });
    });
}

export default { init };
