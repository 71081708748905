import getOffset from "../utils/getOffset";
import DOMPurify from "dompurify";

class FullPageTabs {
    constructor() {
        this.init();
    }

    init() {
        function setNavBorderColor(tabIndex, nav) {
            let navBorderColor;

            switch (tabIndex) {
                case 0:
                    navBorderColor = "#99cc00";
                    break;
                case 1:
                    navBorderColor = "#009966";
                    break;
                case 2:
                    navBorderColor = "#ff9900";
                    break;
            }
            nav[0].style.borderBottomColor = navBorderColor;
        }

        $(".carousel-inner .select-condition a").on("click", function() {
            $(".item .docs").hide();
            $(".docs" + "." + $(this).attr("target")).show();
        });

        //Slider setting to land on the right
        $(".collapse").on("show.bs.collapse", function() {
            if ($(this).find(".before-after-section").length) {
                $("body").removeClass("before-after-interacted");
            }
            setTimeout(function() {
                if (typeof Event === "function") {
                    // modern browsers
                    window.dispatchEvent(new Event("resize"));
                } else {
                    // for IE and other old browsers
                    // causes deprecation warning on modern browsers
                    var evt = window.document.createEvent("UIEvents");
                    evt.initUIEvent("resize", true, false, window, 0);
                    window.dispatchEvent(evt);
                }
            }, 10);
        });

        $(window).on("scroll", function() {
            if ($(".static-nav").length) {
                var scroll = $(window).scrollTop(),
                    stickyOffset = $(".static-nav").offset().top + $(".static-nav").outerHeight();

                scroll >= stickyOffset ? $("body").addClass("tab-nav-fixed") : $("body").removeClass("tab-nav-fixed");
            }
        });

        $('.contentFullPageTabs a[data-toggle="tab"], .contentFullPageTabs + .tab-content a[data-toggle="tab"]').on("show.bs.tab", function() {
            var el = $(this),
                stickyNav = $(".dupixent-indicated .sticky-nav"),
                stickyNavMobile = $(".dupixent-indicated .sticky-nav-mobile"),
                tabIndex = el.parent().index() * 1,
                tabHeading =
                    $(".sticky-nav-mobile .dropdown-menu")
                        .find("li")
                        .eq(tabIndex)
                        .text() + ' <span class="fa fa-chevron-down"></span>';

            //remove active state from all tab navs
            $(".contentFullPageTabs")
                .find(".static-nav li, .sticky-nav li, .sticky-nav-mobile li")
                .removeClass("active");

            //add active state to all tab navs
            $(`.contentFullPageTabs [href="${el.attr("href")}"]`)
                .parent()
                .addClass("active");

            //add active class to parent nav
            $(".static-nav, .sticky-nav, .sticky-nav-mobile")
                .removeClass(function(index, css) {
                    return (css.match(/(^|\s)tab-active-\S+/g) || []).join(" ");
                })
                .addClass("tab-active-" + tabIndex);

            if (stickyNav.length) {
                setNavBorderColor(tabIndex, stickyNav);
            }

            // stickyNavMobile
            if (stickyNavMobile.length) {
                stickyNavMobile.find("#tab-dropdown")[0].innerHTML = tabHeading;
            }

            var targetScroll = document.querySelector(".dupixent-indicated");
            if (targetScroll) {
                scrollTo(getOffset(targetScroll), function() {
                    $("body").addClass("header-hide");
                });
            }
        });

        //function for adding callback to native JS scroll
        function scrollTo(offset, callback) {
            const fixedOffset = offset.toFixed();
            const onScroll = function() {
                if (window.pageYOffset.toFixed() === fixedOffset) {
                    window.removeEventListener("scroll", onScroll);
                    callback();
                }
            };

            window.addEventListener("scroll", onScroll);
            onScroll();
            window.scrollTo({
                top: offset,
                behavior: "smooth"
            });
        }

        const url = new URL(DOMPurify.sanitize(document.location));
        const hash = decodeURI(url.hash);
        const tabPane = $(".tab-pane " + hash);

        //check if there is has within a tab-pane
        if (hash && $(".tab-pane " + hash).length) {
            //event listener and timeout added due to conflicting code that reset scroll position to top
            $(window).on("load", function() {
                setTimeout(function() {
                    const tabId = "#" + tabPane.parents(".tab-pane").attr("id");

                    $('[href="' + tabId + '"]').tab("show");
                    window.scroll(0, $(hash).offset().top);
                }, 100);
            });
        }
    }
}

export default FullPageTabs;
