import WatsonAssistant from "../modules/watsonAssistantChatBot";

function init() {
    ["mousemove", "scroll", "touchstart", "click"].forEach(evt => document.addEventListener(evt, callWatson));
}
function callWatson(evt) {
    if (!window.WatsonAssistant) {
        new WatsonAssistant(evt);
        ["mousemove", "scroll", "touchstart", "click"].forEach(evt => document.removeEventListener(evt, callWatson));
    }
}

export default { init };
