const contentTab = document.querySelectorAll(".gallery-wrapper li.videos-tab, .injection-instruction-videos li.videos-tab");

const tabContent = document.querySelectorAll(".tab-content");

const init = () => {
    contentTab.forEach(tab => {
        tab.addEventListener("click", () => {
            tabContent.forEach(tab => {
                tab.scrollTop = 0;
            });
        });
    });
};
export default { init };
