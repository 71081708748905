/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import Player from "@vimeo/player";
import DOMPurify from "dompurify";
import PageBlocked from "./pageBlocked";
import removeTrailingSlash from "../utils/removeTrailingSlash";
import setCookie from "../utils/setCookie";
import checkCookie from "../utils/checkCookie";
import getOffset from "../utils/getOffset";
import getParameterByName from "../utils/getParameterByName";

let instanceWatsonWebChat;
let isLoading = false;
var language = document.getElementsByTagName("html")[0].getAttribute("lang");

function chatMenu() {
    if (language == "en-US") {
        return `<div class="dropdown">
                <button id="WAC__menu-toggle" class="dropdown-toggle" type="button" data-toggle="dropdown">
                    <span class="caret"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="WAC__menu-toggle">
                    <li><a href="#" data-watson="start-over">Start Over</a></li>
                    <li><a href="#" data-watson="live-agent">Talk to Someone</a></li>
                    <li><a href="#" data-watson="leave-feedback">Give Feedback</a></li>
                </ul>
                </div>`;
    } else {
        return `<div class="dropdown">
                <button id="WAC__menu-toggle" class="dropdown-toggle" type="button" data-toggle="dropdown">
                    <span class="caret"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="WAC__menu-toggle">
                    <li><a href="#" data-watson="start-over">Comenzar de nuevo</a></li>
                    <li><a href="#" data-watson="live-agent">Hablar con alguien</a></li>
                    <li><a href="#" data-watson="leave-feedback">Dar mis sugerencias</a></li>
                </ul>
                </div>`;
    }
}

function getCustomStartPage() {
    // Start chat at specific point if desired
    var page_url = removeTrailingSlash(window.location.pathname);
    var startText = null;

    // SCENARIO 1: Parameter in URL / Specific URL custom starting point
    if (page_url.endsWith("injection-support-center") || page_url.indexOf("how-dupixent-is-taken") >= 0) {
        startText = "Injection_Support_Welcome";
    } else if (page_url.endsWith("copay-card") || page_url.indexOf("cost-insurance") >= 0) {
        startText = "Cost_and_Insurance_Welcome";
    } else if (page_url.indexOf("dupixent-my-way") >= 0) {
        startText = "Patient_Support_Welcome";
    } else if (page_url.endsWith("staying-on-track")) {
        startText = "Help_Staying_OnTrack";
    }

    return startText;
}

function sendCustomMessage(message, restart) {
    if (message && message.length > 0) {
        var sendObject = {
            input: {
                message_type: "text",
                text: message
            },
            options: {
                restart: restart
            }
        };
        var sendOptions = {
            silent: true
        };
        instanceWatsonWebChat.send(sendObject, sendOptions).then(
            function() {
                console.log("Custom message sent " + message);
            },
            function(e) {
                console.error("This message did not send! " + e);
            }
        );
    }
}

function handleVideoCTA(event) {
    let title = DOMPurify.sanitize(event.data.message.user_defined.title);
    let vimeoID = DOMPurify.sanitize(event.data.message.user_defined.vimeoID);
    let brightcoveID = DOMPurify.sanitize(event.data.message.user_defined.videoID);
    let videoID = "";
    let isVimeo = true;

    //check if vimeoID exists, if so, use that for VideoID
    if (vimeoID) {
        videoID = vimeoID;
        isVimeo = true;
    } else {
        videoID = brightcoveID;
        isVimeo = false;
    }

    let videoThumb = DOMPurify.isValidAttribute("img", "src", event.data.message.user_defined.image) ? event.data.message.user_defined.image : "";
    let node = DOMPurify.sanitize(`<a href="#" class="wac_videolink" data-is-vimeo="${isVimeo}" data-video-id="${videoID}" data-video-title="${title}"><img src="${videoThumb}"><span class="caption">${title}</span><span class="click-me">WATCH VIDEO</span></a>`);
    event.data.element.innerHTML = node;
}

function handleCTA(event) {
    let modalToggle = "";
    if (language == "en-US") {
        modalToggle = 'data-toggle="modal"';
    }

    // Add hooks to retain target="_blank" links with DOMPurify
    const TEMPORARY_ATTRIBUTE = "data-temp-href-target";

    DOMPurify.addHook("beforeSanitizeAttributes", function(node) {
        if (node.tagName === `A`) {
            if (node.hasAttribute(`target`) && node.classList.contains("wac_ctalink")) {
                node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute(`target`));
            }
        }
    });

    DOMPurify.addHook("afterSanitizeAttributes", function(node) {
        if (node.tagName === `A` && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
            node.setAttribute(`target`, node.getAttribute(TEMPORARY_ATTRIBUTE));
            node.removeAttribute(TEMPORARY_ATTRIBUTE);
            if (node.getAttribute(`target`) === `_blank`) {
                node.setAttribute(`rel`, `noopener noreferrer nofollow`);
            }
        }
    });

    var href = DOMPurify.isValidAttribute("a", "href", event.data.message.user_defined.href) ? event.data.message.user_defined.href : "#",
        title = DOMPurify.sanitize(event.data.message.user_defined.title),
        target = DOMPurify.sanitize(event.data.message.user_defined.target) ? `data-remote="false" data-target="#thirdPartyMod" ${modalToggle} target="_blank"` : "",
        node = DOMPurify.sanitize(`<a href="${href}" class="wac_ctalink" ${target}">${title}</a>`),
        elem = new DOMParser().parseFromString(node, "text/html").body.firstElementChild;

    console.log("this is the href", href);

    event.data.element.innerHTML = node;
    $(elem).on("click", function(e) {
        var targetAttr = DOMPurify.sanitize(this.getAttribute("target"));
        if (href.indexOf("#") > -1) {
            var currentPath = removeTrailingSlash(window.location.pathname),
                destPath = href.split("#")[0],
                destHash = href.split("#")[1];
            if (destPath === currentPath) {
                e.preventDefault();
                if (window.outerHeight < 500 || window.outerWidth < 500) instanceWatsonWebChat.closeWindow();
                const targetScroll = document.querySelector(`#${destHash}`);
                if (targetScroll) {
                    window.scrollTo({
                        top: getOffset(targetScroll),
                        behavior: "smooth"
                    });
                }
            } else {
                if (targetAttr.indexOf("_blank") > -1) {
                    event.preventDefault();
                    window.open(DOMPurify.sanitize(href), "_blank");
                } else {
                    document.location = DOMPurify.sanitize(href);
                }
            }
        } else {
            if (targetAttr.indexOf("_blank") > -1) {
                event.preventDefault();
                window.open(DOMPurify.sanitize(href, "_blank"));
            } else {
                document.location = DOMPurify.sanitize(href);
            }
        }
    });
}

function handleExitCTA(event) {
    var title = DOMPurify.sanitize(event.data.message.user_defined.title);
    let node = DOMPurify.sanitize(`<div class="WAC__button-holder"><ul><li><button class="WAC__button--base WAC__button--round WAC__button WAC__button--exit" type="button">${title}</button></li></ul></div>`);
    event.data.element.innerHTML = node;

    let elem = event.data.element.querySelector("button");
    function clickFunction() {
        if (!elem.disabled && sessionStorage.IBM_WAC_EXITED !== "true") {
            elem.classList.add("WAC__button--selected");
            elem.disabled = true;
            elem.removeEventListener("click", clickFunction);
            sessionStorage.setItem("IBM_WAC_EXITED", true);
            if (instanceWatsonWebChat.getState().isWebChatOpen) {
                instanceWatsonWebChat.closeWindow();
            }
            if (sessionStorage.surveyDisplayed !== "true") {
                handleForm("feedback");
            }
        }
    }
    elem.addEventListener("click", clickFunction);
}

function handleSurveyResponse() {
    var rating = $("form input[name='inlineRadioOptions']:checked").val();
    sessionStorage.setItem("surveyRating", rating);
    $(".watson-survey .modal-content").addClass("hidden");
    $(".watson-survey .modal-content.success").removeClass("hidden");
}

/******************************************
 * Watson Custom Handlers
 *****************************************/
function customPreSendHandler(event) {
    var customStartMessage = getCustomStartPage(instanceWatsonWebChat);
    //TEST: if there is no chat history and there's a custom start page, inject the custom start message response into the first send.
    if (event.data.history.getWelcome === true && customStartMessage) {
        event.data.input.text = customStartMessage;
    }
}

function customSendHandler() {
    //console.log(event);
    // Get the current event
    //var eventOutput = event.data;
    //var eventText = eventOutput.input.text;
}

function customPreReceiveHandler(event) {
    /**
     * IMPORTANT: Temporary workaround until
     * user_defined is a supported response type in Watson Assistant.
     *
     * If "output.user_defined.template_name" has a value, we convert the response to follow the
     * planned "user_defined" response type. We will replace the "text" response type with
     * "replace with user_defined" as its value.
     *
     * @param event The event passed from Watson Assistant
     * @param event.type The type of event, in this case "pre:receive".
     * @param event.data.message The original message.
     */

    if (event.data.output.user_defined && event.data.output.user_defined.template_name) {
        // Map over all items in the output array.
        event.data.output.generic = event.data.output.generic.map(function(item) {
            // If we find one that matches our convention to transform to user_defined response type, make the transformation.
            item.response_type = "user_defined";
            item.user_defined = event.data.output.user_defined;
            delete item.text;

            return item;
        });

        // Add a return message to end of response
        var responseOptions = event.data.output.user_defined.response_option;
        var responseOptionsLength = responseOptions.length;

        // Loop through each response and add it in
        for (var i = 0; i < responseOptionsLength; i++) {
            var responseOption = {
                title: "",
                options: [
                    {
                        label: event.data.output.user_defined.response_option[i],
                        value: {
                            input: {
                                text: event.data.output.user_defined.response_option[i]
                            }
                        }
                    }
                ],
                response_type: "option"
            };
            event.data.output.generic.push(responseOption);
        }
    }
}

function handleForm(data) {
    var modal = $("#modalForm");
    if (data && data == "feedback") {
        modal.find("input:radio").on("click", function() {
            handleSurveyResponse(data);
        });
        $(".watson-survey .modal-content").removeClass("hidden");
        $(".watson-survey .modal-content.success").addClass("hidden");
        sessionStorage.setItem("surveyDisplayed", true);
        modal.modal("show");
    }
}

function customWindowOpenHandler() {
    $("body").addClass("watson-open");
    setCookie("attention-bubble-closed", true);
    $(".attention-bubble").removeClass("animate-bubble");
    $("body").addClass("pixi-minimized");
    var exitButton = $(".WAC__messages .WAC__message")
        .last()
        .find(".WAC__button--exit");
    if (exitButton && exitButton[0] !== undefined) {
        if (sessionStorage.IBM_WAC_EXITED === "true") {
            exitButton.addClass("WAC__button--selected");
            exitButton[0].disabled = true;
        }
        var customStartMessage = getCustomStartPage(instanceWatsonWebChat);
        if (customStartMessage) {
            sendCustomMessage(customStartMessage, true);
        } else {
            sendCustomMessage("Main_Menu", true);
        }
    }
    sessionStorage.setItem("IBM_WAC_EXITED", false);
}

function customWindowCloseHandler() {
    $("body").removeClass("watson-open");
}

function customResponseHandler(event) {
    var template = event.data.message.user_defined.template_name;
    switch (template) {
        case "cta":
            handleCTA(event, "Custom Response CTA");
            break;
        case "video":
            handleVideoCTA(event, "Custom Response CTA");
            break;
        case "dynamic_content":
            handleCTA(event, "Custom Response Dynamic Content");
            break;
        case "exit_cta":
            handleExitCTA(event, "Custom Response Exit CTA");
            break;
        default:
            console.error("Unhandled response type.");
            break;
    }
}

class WatsonAssistant {
    constructor(event) {
        this.watsonAssistant = document.querySelector("[data-watson-assistant]");
        if (!this.watsonAssistant) return;
        this.integrationId = this.watsonAssistant.dataset.integrationId;
        this.region = this.watsonAssistant.dataset.region;
        this.serviceId = this.watsonAssistant.dataset.serviceId;
        this.pageBlocked = this.watsonAssistant.dataset.pageBlocked;
        this.event = event;
        this.init();
    }

    config() {
        const $this = this;
        window.watsonAssistantChatOptions = {
            integrationID: $this.integrationId, // The ID of this integration.
            region: $this.region, // The region your integration is hosted in.
            serviceInstanceID: $this.serviceId, // The ID of your service instance.
            learningOptOut: true,
            isTrackingEnabled: false,
            debug: false, // For development
            clientVersion: "4.4.1",
            onLoad: function(instance) {
                instance.on({
                    type: "pre:receive",
                    handler: customPreReceiveHandler
                });
                instance.on({
                    type: "customResponse",
                    handler: customResponseHandler
                });
                instance.on({
                    type: "pre:send",
                    handler: customPreSendHandler
                });
                instance.on({
                    type: "send",
                    handler: customSendHandler
                });
                instance.on({
                    type: "window:open",
                    handler: customWindowOpenHandler
                });
                instance.on({
                    type: "window:close",
                    handler: customWindowCloseHandler
                });
                instance.updateCSSVariables({
                    "BASE-font-family": '"Helvetica Neue", Helvetica, Arial, sans-serif',
                    "SECONDARY-color-text": "#000" //,
                });
                instance.writeableElements.headerBottomElement.innerHTML = chatMenu();
                instance.render().then(function() {
                    isLoading = true;
                    instanceWatsonWebChat = instance;
                    if (!checkCookie("pixi-icon-bounce")) {
                        $("body").addClass("pixi-icon-bounce");
                        setCookie("pixi-icon-bounce", true);
                    }
                    if (language == "en-US") {
                        $(".WACLauncher__ButtonContainer").append("<div class='attention-bubble'><button class='fas fa-times close-bubble'></button>Hello, I'm Pixi! <br>What can I help you learn <br>more about today?</div>");
                    } else {
                        $(".WACLauncher__ButtonContainer").append("<div class='attention-bubble'><button class='fas fa-times close-bubble'></button>¡Hola, soy Pixi! <br>¿Sobre qué puedo ayudarte  <br>a aprender más hoy?</div>");
                    }
                    if (checkCookie("attention-bubble-closed")) {
                        $(".attention-bubble").addClass("hidden-xs");
                    } else {
                        setTimeout(function() {
                            $(".attention-bubble").addClass("animate-bubble");
                        }, 2000);
                    }
                    document.getElementById("watson-facade").style.display = "none";
                    if (($this.event.type == "touchstart" || $this.event.type == "click") && $this.event.target.id == "watson-facade") {
                        instanceWatsonWebChat.openWindow();
                    }
                });
                isLoading = false;
            }
        };
    }

    removeSessionOnMobile() {
        for (var i = sessionStorage.length - 1; i >= 0; i--) {
            if (sessionStorage.key(i).lastIndexOf("IBM_WAC_SESSION", 0) === 0) {
                var keyStr = sessionStorage.key(i);
                if (window.outerHeight < 500 || window.outerWidth < 500) {
                    sessionStorage[keyStr] = sessionStorage[keyStr].replace('"isOpen":true', '"isOpen":false');
                }
            }
        }
    }
    initModalListeners() {
        $("#modalVideo")
            .on("show.bs.modal", function(e) {
                let videoId = $(e.relatedTarget).data("video-id");
                let isVimeo = $(e.relatedTarget).data("is-vimeo");
                let $vimeoPlayButton = $(this).find(".vimeo-play-button");

                if (isVimeo) {
                    $(this)
                        .find("#modal-vimeo-wrapper")
                        .removeClass("hidden");
                    $(this)
                        .find("video-js")
                        .addClass("hidden");

                    let player = new Player("modal-vimeo-player", { url: videoId });

                    player.on("play", () => {
                        if (!$vimeoPlayButton.hasClass("hidden")) {
                            $vimeoPlayButton.addClass("hidden");
                        }
                    });

                    $vimeoPlayButton.on("click", () => {
                        $vimeoPlayButton.addClass("hidden");

                        player.setVolume(1);
                        player.play();
                    });
                } else {
                    $vimeoPlayButton.addClass("hidden");
                    $(this)
                        .find("#modal-vimeo-wrapper")
                        .addClass("hidden");
                    $(this)
                        .find("video-js")
                        .removeClass("hidden");
                    // eslint-disable-next-line no-undef
                    let player = videojs($(this).find(".video-js")[0]);

                    document.querySelector("#modalVideo").dataset.videoId = videoId;

                    player.catalog.getVideo(videoId, function(error, video) {
                        player.catalog.load(video);
                    });
                }

                $("#modalTranscriptCollapse").collapse("hide");
                $("#modalTranscriptCollapse").removeClass("hidden");
            })
            .on("hide.bs.modal", function(e) {
                let isVimeo = $(e.relatedTarget).data("is-vimeo");

                if (isVimeo) {
                    let player = new Player("modal-vimeo-player");
                    $(this)
                        .find(".vimeo-play-button")
                        .removeClass("hidden");
                    player.pause();
                    player.destroy();
                } else {
                    // eslint-disable-next-line no-undef
                    videojs($(this).find(".video-js")[0]).pause();
                }
            });

        var videoModalID = getParameterByName("video-modal");

        if (videoModalID) $('[data-toggle][data-video-id="' + videoModalID + '"]').trigger("click");

        $("#thirdPartyMod .close").on("click", function() {
            if ($("#ibm-assistant-content").is(":visible")) {
                $("body").addClass("modal-open");
            }
        });
    }
    initBodyEvent() {
        const $this = this;

        this.initModalListeners();

        var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        $(".WAC__cta .timezone").text(timezone);
        if (PageBlocked($this.pageBlocked)) return;
        // Send chat back to start
        $("body").on("click", '[data-watson="start-over"]', function(e) {
            e.preventDefault();
            sendCustomMessage("Main_Menu", true);
            $("#headerBottomElement .dropdown").removeClass("open");
        });

        $("body").on("click", '[data-watson="leave-feedback"]', function(e) {
            handleForm("feedback");
            $("#headerBottomElement .dropdown").removeClass("open");
        });
        $("body").on("click", '[data-watson="live-agent"]', function(e) {
            e.preventDefault();
            sendCustomMessage("Live_Agent", false);
            $("#headerBottomElement .dropdown").removeClass("open");
        });
        $("body").on("click", ".wac_videolink", function(e) {
            e.preventDefault();
            var videoID = $(this).data("video-id"),
                videoTitle = $(this).data("video-title");
            let isVimeo = $(this).data("is-vimeo");

            $("#modalVideo .modal-title").html(videoTitle);
            $(".video-modal-transcript")
                .addClass("hidden")
                .filter('[video-id="' + videoID + '"]')
                .removeClass("hidden");

            $('<a href="#" data-toggle="modal" data-is-vimeo="' + isVimeo + '" data-target="#modalVideo" data-video-id="' + videoID + '"></a>')
                .appendTo("body")
                .trigger("click")
                .remove();
        });

        $("body").on("click", ".close-bubble", function() {
            $(".attention-bubble").removeClass("animate-bubble");
            $("body").addClass("pixi-minimized");
            setCookie("attention-bubble-closed", true);
        });

        var pixiScrollDist = $(window).scrollTop();
        $(window).on("scroll", handleWindowScrollForPixi);

        function handleWindowScrollForPixi(e) {
            var traveledDist = Math.abs(pixiScrollDist - $(window).scrollTop());
            if (traveledDist > 200) {
                $(".close-bubble").trigger("click");
                $(window).off("scroll", handleWindowScrollForPixi);
            }
        }
        if (checkCookie("attention-bubble-closed")) $("body").addClass("pixi-minimized");
        $("body").on("mouseover", ".WACLauncher__Button", function() {
            $(this)
                .parent(".WACLauncher__ButtonContainer")
                .addClass("hover");
        });

        $("body").on("mouseout", ".WACLauncher__Button", function() {
            $(this)
                .parent(".WACLauncher__ButtonContainer")
                .removeClass("hover");
        });

        $(".isi.sticky").on("click", function() {
            $("body").removeAttr("style");
            instanceWatsonWebChat.closeWindow();
        });

        $("body").on("click", ".wac_ctalink", function(e) {
            if (e.currentTarget.href.indexOf("#") > -1) {
                var currentPath = removeTrailingSlash(window.location.pathname),
                    destPath = e.currentTarget.href.split("#")[0],
                    destHash = e.currentTarget.href.split("#")[1];
                if (destPath !== currentPath) {
                    return;
                } else {
                    e.preventDefault();
                    if (window.outerHeight < 500 || window.outerWidth < 500) instanceWatsonWebChat.closeWindow();
                    const targetScroll = document.querySelector(`#${destHash}`);
                    if (targetScroll) {
                        window.scrollTo({
                            top: getOffset(targetScroll),
                            behavior: "smooth"
                        });
                    }
                }
            }
        });
        window.onbeforeunload = function() {
            isLoading = false;
        };
    }

    loadWatsonChat() {
        const $this = this;
        if (PageBlocked($this.pageBlocked)) return;
        const t = document.createElement("script");
        t.src = "https://web-chat.global.assistant.watson.appdomain.cloud/loadWatsonAssistantChat.js";
        t.defer = true;
        document.head.appendChild(t);
    }

    init() {
        this.config();
        this.loadWatsonChat();
        this.removeSessionOnMobile();
        this.initBodyEvent();
    }
}

export default WatsonAssistant;
