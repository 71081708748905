function slideModal() {
    let slides = document.querySelectorAll(".slide-quiz .slide");
    let slideData = null;
    let correctModal = document.querySelector(".correct-modal");
    let incorrectModal = document.querySelector(".incorrect-modal");
    let index = 0;

    if (!document.querySelector(".slide-quiz")) return;

    // Logic for each button on slide quiz
    document.querySelectorAll(".slide-quiz a[role='button']").forEach(button => {
        ["click", "keypress"].forEach(evt => {
            button.addEventListener(evt, e => {
                if (button.classList.contains("slide-btn") || button.classList.contains("modal-btn") || button.classList.contains("start-button")) {
                    nextSlide(evt, e);
                } else if (button.classList.contains("prev-btn")) {
                    prevSlide(evt, e);
                } else if (button.classList.contains("modal-close-btn")) {
                    closeModal(evt, e);
                } else if (button.classList.contains("restart-btn")) {
                    resetQuiz(evt, e);
                } else if (button.classList.contains("choice-btn")) {
                    checkAnswer(button.dataset.choice, evt, e);
                }
            });
        });
    });

    // Increase Question Slide Height if modal content size is larger
    window.addEventListener("resize", () => {
        let modalHeight = correctModal.offsetHeight > incorrectModal.offsetHeight ? correctModal.offsetHeight : incorrectModal.offsetHeight;
        slides[index].style.setProperty("--modal-slide-height", `${modalHeight + 180}px`);
    });

    // Moves to next slide of slide quiz
    function nextSlide(evt, e) {
        closeModal();
        slides[index++].classList.remove("active");
        slides[index].classList.add("active");
        slideData = slides[index].querySelector(".slide-data");

        // Do focus logic for accessibility
        if (evt == "keypress" && e.key == "Enter") {
            if (slides[index].querySelector(".choice-btn")) slides[index].querySelector(".choice-btn").focus();
            else if (slides[index].classList.contains("end-slide")) slides[index].querySelector(".slide-btn").focus();
        }
    }

    function closeModal(evt, e) {
        correctModal.classList.remove("active");
        incorrectModal.classList.remove("active");
        slides[index].parentNode.style.setProperty("--slide-before-display", "none");
        slides[index].style.setProperty("--modal-slide-height", "unset");

        // Do focus logic for accessibility
        if (evt == "keypress" && e.key == "Enter") {
            if (slides[index].querySelector(".choice-btn")) slides[index].querySelector(".choice-btn").focus();
            else if (slides[index].classList.contains("end-slide")) slides[index].querySelector(".slide-btn").focus();
        }
    }

    // Moves to previous slide of slide quiz
    function prevSlide(evt, e) {
        slides[index--].classList.remove("active");
        slides[index].classList.add("active");
        slideData = slides[index].querySelector(".slide-data");

        // Do focus logic for accessibility
        if (evt == "keypress" && e.key == "Enter") {
            if (slides[index].querySelector(".choice-btn")) {
                slides[index].querySelector(".choice-btn").focus();
            } else if (slides[index].classList.contains("end-slide") || slides[index].classList.contains("slide-btn")) {
                slides[index].querySelector(".slide-btn").focus();
            }
        }
    }

    // Restarts Quiz (start screen)
    function resetQuiz(evt, e) {
        slides[index].classList.remove("active");
        slides[0].classList.add("active");
        index = 0;

        // Do focus logic for accessibility
        if (evt == "keypress" && e.key == "Enter") {
            slides[0].querySelector(".slide-btn").focus();
        }
    }

    // Each Slide has their own answer data attribute
    // Grab that and compare with user selection
    function checkAnswer(selection, evt, e) {
        let modal = null;
        if (slideData.dataset.answer.trim().toLowerCase() == selection.trim().toLowerCase()) {
            modal = correctModal;
            updateModal(modal);
        } else {
            modal = incorrectModal;
            updateModal(modal);
        }

        // Do focus logic for accessibility
        if (evt == "keypress" && e.key == "Enter") {
            modal.querySelector(".modal-btn").focus();
        }
    }

    // Populates relevant modal based of current slide using slide data.
    function updateModal(modal) {
        modal.classList.add("active");

        if (modal == correctModal) {
            modal.querySelector(".modal-heading").innerHTML = slideData.dataset.modalCorrectHeading;
            modal.querySelector(".modal-text").innerHTML = slideData.dataset.modalCorrectContent;
            modal.querySelector(".modal-btn .modal-btn-text").innerHTML = slideData.dataset.modalCorrectBtn;
        } else {
            modal.querySelector(".modal-heading").innerHTML = slideData.dataset.modalIncorrectHeading;
            modal.querySelector(".modal-text").innerHTML = slideData.dataset.modalIncorrectContent;
            modal.querySelector(".modal-btn .modal-btn-text").innerHTML = slideData.dataset.modalIncorrectBtn;
        }

        slides[index].parentNode.style.setProperty("--slide-before-display", "block");

        // Increase Question Slide Height if modal content size is larger
        slides[index].style.setProperty("--modal-slide-height", `${modal.offsetHeight + 180}px`);
    }
}

export default slideModal;
