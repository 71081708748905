import scrollToElement from "../utils/scrollToElement";
import DOMPurify from "dompurify";

function init() {
    //set first videos in player to active
    $(".first").addClass("active");
    videoJS();
}

function sortTracks(player) {
    for (let i = 0; i < player.remoteTextTracks().length; i++) {
        if (player.remoteTextTracks()[i].kind === "chapters") {
            let tracks = player.remoteTextTracks()[i];
            return tracks;
        }
    }
}
function getChapter(elem, player) {
    let chapters = $($(elem).data("chapters-container"));

    //check for chapters container next to video
    if (!chapters.length) return false;

    player.ready(function() {
        let tracks = sortTracks(player);

        //check if metadata type is chapters
        //look for cues
        if (tracks) {
            //empty chapters container
            chapters.html("");
            //loop through chapter metadata
            for (var i = 0; i < tracks.cues.length; i++) {
                var cue = tracks.cues[i],
                    // eslint-disable-next-line no-undef
                    $el = $('<li><span class="start-time">' + videojs.formatTime(cue.startTime, "M:SS") + "</span> " + cue.text + "</li>");
                //create chapter elements
                $el.data("time", cue.startTime)
                    .on("click", $.proxy(handleChapterChange, $el, player))
                    .appendTo(chapters);
                cue.el = $el;
                tracks.addEventListener("cuechange", $.proxy(updateChapters, cue, player));
            }
        }
    });

    // eslint-disable-next-line no-inner-declarations
    function updateChapters(player) {
        let currentTime = player.currentTime();

        (this.el, this.startTime <= currentTime && currentTime < this.endTime) ? this.el.addClass("active") : this.el.removeClass("active");
    }

    // eslint-disable-next-line no-inner-declarations
    function handleChapterChange(player) {
        player.currentTime($(this).data("time"));
    }
}

function handleTranscriptToggle(player, isAdAdherence = false) {
    if (!isAdAdherence) {
        if (
            $(player)
                .parent()
                .find(".transcriptToggle")
        ) {
            $(player)
                .parent()
                .find(".transcriptToggle")
                .addClass("js-expanded")
                .children()
                .toggleClass("hidden");
        }
        $(player)
            .parent()
            .find(".transcript")
            .removeClass("hidden");
    } else {
        if (
            $(player)
                .parent()
                .find(".transcriptToggle")
        ) {
            $(player)
                .parent()
                .find(".transcriptToggle")
                .children();
        }
        $(player)
            .parent()
            .find(".transcript");
    }
}

function videoJS() {
    // eslint-disable-next-line no-undef

    if (window.videojs) {
        $("video-js, .video-js").each(function() {
            // eslint-disable-next-line no-undef
            var player = videojs(this);
            //Check if page is a crm page
            if (!document.querySelector("body.crm")) {
                $(this)
                    .parent()
                    .find(".transcript")
                    .addClass("hidden");
            }

            //If video js already has attribute 'muted', keep it muted. Else, initialize the video unmuted
            if (this.getAttributeNames().includes("muted")) {
                player.muted(true);
            } else {
                player.muted(false);
            }

            getChapter(this, player);

            player.bigPlayButton.on(["click", "touchstart"], () => {
                let isAdAdherence = document.body.classList.contains("adAdherence");
                player.ready(() => {
                    handleTranscriptToggle(this, isAdAdherence);
                });
            });

            this.addEventListener("click", () => {
                pauseOtherVideos(player);
            });
        });
    }

    $(".instruction-opener, .section-instruction-grid .collapsible__list a").on("click", function(e) {
        e.preventDefault();
        $(".section-instruction-media").removeClass("invisible");
        $(".section-instruction-grid").addClass("invisible");

        let vimeoLink = e.target.parentNode.dataset.vimeoLink;

        // Return if vimeo.
        if (vimeoLink) {
            return;
        }

        var videoLink = $('.video-link[href="' + $(this).attr("href") + '"]');
        var paneWrapper = videoLink.parents(".pane-wrapper");

        videoLink.trigger("click", false);

        if (!videoLink.position()) {
            return;
        }
        paneWrapper.scrollTop(videoLink.position().top);

        let href = e.target.closest("a").href;
        let hashIndex = href.indexOf("#");
        let playerId = href.substring(hashIndex);

        // eslint-disable-next-line no-undef
        let player = videojs(playerId);

        player.catalog.getVideo(playerId, function(error, video) {
            player.catalog.load(video);
            player.play().then(() => {
                getChapter(document.querySelector(playerId), player);
            });

            player.muted(false);
        });
    });

    $("[data-instruction-set]").on("click", function() {
        var instructionID = $(this).data("instruction-set");
        $(`[data-target="${instructionID}"]`).tab("show");
    });

    const url = new URL(DOMPurify.sanitize(document.location));
    const hash = decodeURI(url.hash);

    $('.instruction-opener[href="' + hash + '"]').trigger("click");

    $("#thumbs-injection .pane-wrapper").on("scroll", function() {
        this.scrollTop + this.offsetHeight + 1 >= this.scrollHeight
            ? $(this)
                  .parent()
                  .addClass("scroll-to-bottom")
            : $(this)
                  .parent()
                  .removeClass("scroll-to-bottom");
    });

    let hashArray = [];

    // check if video parent has videojs element
    let videojsPlayer = document.querySelector(".contentVideoGallery video-js");

    if (videojsPlayer) {
        // debug: show videojs player id
        // console.log(videojsPlayer.id);
        //Show id depending on hash
        let videoAnchors = document.querySelectorAll("[data-id-anchor]");
        //clear all active classes on li
        if (hash !== "") {
            videoAnchors.forEach(anchor => {
                let attr = anchor.dataset.idAnchor.toLowerCase().trim();

                hashArray.push(attr);

                //uncomment for debugging
                //console.log("DEBUGGING: " + attr, hash.toLowerCase()); //these should match

                if (hash.toLowerCase() === attr) {
                    //remove first active
                    let firstChild = anchor.closest("ul");
                    firstChild.firstElementChild.classList.remove("active");
                    //set li to active class
                    anchor.parentNode.classList.add("active");

                    let videoId = anchor.dataset.videoId;
                    let playerId = anchor.dataset.videoPlayerId;
                    // eslint-disable-next-line no-undef
                    let player = videojs(playerId);

                    player.catalog.getVideo(videoId, function(error, video) {
                        player.catalog.load(video);
                        let playPromise = player.play();
                        playPromise.then(
                            () => {
                                getChapter(document.querySelector(playerId), player);
                                player.muted(false);
                            },
                            () => {
                                getChapter(document.querySelector(playerId), player);
                                player.muted(false);
                            }
                        );

                        let playerContainer = document.querySelector(playerId);
                        let offsetNum = playerContainer.dataset.offset;

                        getChapter(document.querySelector(playerId), player);

                        window.scrollTo({
                            behavior: "smooth",
                            top: playerContainer.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offsetNum
                        });

                        // scroll to active thumb in thumbnail pane
                        const thumbPane = firstChild.closest(".pane-wrapper");
                        let thumbOffset = anchor.parentElement.offsetTop;
                        thumbPane.scrollTop = thumbOffset;
                    });
                }
            });
        }
    }

    // eslint-disable-next-line no-undef
    if (document.querySelector("video-js, .video-js")) {
        $(".video-link").on("click", function(e) {
            let vimeoLink = e.target.parentNode.dataset.vimeoLink;

            // Return if vimeo.
            if (vimeoLink) {
                return;
            }

            e.preventDefault();
            var videoId = $(e.currentTarget).data("video-id"),
                playerId = $(e.currentTarget).data("video-player-id");
            // eslint-disable-next-line no-undef
            let player = videojs(document.querySelector(playerId));

            player.catalog.getVideo(videoId, function(error, video) {
                player.catalog.load(video);
                player.play().then(() => {
                    getChapter(document.querySelector(playerId), player);
                });

                player.muted(false);
            });
            pauseOtherVideos(player);
            scrollToElement(playerId);
        });
    }

    $("[data-doc-target]").click(function() {
        $(".docs").hide();
        $("." + $(this).data("doc-target")).show();
    });

    if (hash) {
        $(`${hash}.collapse`).collapse("show");
    }

    if ($(hash).parents(".faq-item").length) {
        scrollToElement($(hash).parents(".faq-item"));
    }

    conditionListHero();
    videoPause();
}

function pauseOtherVideos(curPlayer) {
    $(".video-js").each(function() {
        // eslint-disable-next-line no-undef
        let player = videojs(this);
        if (player.id() !== curPlayer.id()) {
            player.pause();
        }
    });
}

function conditionListHero() {
    let video;

    if ($(".bg-video .hero-dropdown").length > 0) {
        // eslint-disable-next-line no-undef
        video = videojs($(".bg-video:visible")[0]);
    } else {
        return;
    }

    var bodyActiveClass = "condition-dropdown-open";
    var dropdownNoAnimateClass = "condition-dropdown-animation-off";

    $("#conditionListToggle").on("click", function(e) {
        e.preventDefault();

        $("body")
            .removeClass(dropdownNoAnimateClass)
            .toggleClass(bodyActiveClass);
        $(this).toggleClass("active");

        if (!$("body").hasClass("bg-video-manually-paused")) {
            // Play/pause video
            if (video.paused()) {
                video.play();
            } else {
                video.pause();
            }
        }
    });
}
function videoPause() {
    $("#pause button").on("click", function(e) {
        e.preventDefault();
        // eslint-disable-next-line no-undef
        var player = videojs.getPlayer($(".bg-video:visible")[0]);

        if (player.paused()) {
            player.play();
            $("body").removeClass("bg-video-manually-paused");
            $("#playBtn").addClass("hidden");
            $("#pauseBtn").removeClass("hidden");
        } else {
            player.pause();
            $("body").addClass("bg-video-manually-paused");
            $("#playBtn").removeClass("hidden");
            $("#pauseBtn").addClass("hidden");
        }
    });
}

export default { init };
