const flipCard = config => {
    return {
        config: { ...config },
        isOpen: false,
        init() {
            this.$refs.flipcardFront.style.backgroundColor = this.config.frontCardColor;
            this.$refs.flipcardBack.style.border = `4px solid ${this.config.backCardColor}`;
        },
        flipCard() {
            if (document.querySelector(".mgnlAdmin")) return;

            this.isOpen = !this.isOpen;
        }
    };
};

export default flipCard;
