function triggerISI() {
    if ($(window).width() < 460) {
        let viewportHeight = $(window).height(),
            topISI = document.getElementById("isi").getBoundingClientRect().top;

        viewportHeight - topISI > 105 ? $(".isi.sticky").addClass("invisible") : $(".isi.sticky").removeClass("invisible");
    }
}

export default triggerISI;
