export default function documentMailer() {
    const triggers = document.querySelectorAll(".email-link, .email-link-pdf, .callout-email-link");
    const modal = document.getElementById("email-doc-modal");

    for (let index = 0; index < triggers.length; index++) {
        const trigger = triggers[index];
        trigger.addEventListener("click", () => {
            const data = trigger.dataset;
            const title = data.bsTitle;
            const document = data.bsDocument;
            const jobCode = data.jobCode;
            const lastUpdate = data.lastUpdate;
            const indication = data.indication;
            let template = data.bsTemplate;

            const form = modal.querySelector("form");
            form.querySelector("[name='title']").value = title;
            form.querySelector("[name='document']").value = document;
            form.querySelector("[name='jobCode']").value = jobCode;
            form.querySelector("[name='lastUpdate']").value = lastUpdate;
            form.querySelector("[name='template']").value = template;
            form.querySelector("[name='indication']").value = indication;
        });
    }
}
