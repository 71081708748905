import updateProgressbar from "./updateProgressBar";
import scrollToElement from "../../../utils/scrollToElement";

function progressBar(dataAttr) {
    var isAtopic = document.querySelector(".results-section.atopic");
    var sectionProgress = $(".progress-bar-list"),
        currentItem = sectionProgress.children().filter("[data-step='" + dataAttr + "']");
    sectionProgress.children().removeClass("active");
    currentItem
        .addClass("active")
        .prevAll()
        .addClass("completed");
    updateProgressbar();
    scrollToElement(".progress-bar-list");

    //ONLY FOR ATOPIC
    if (isAtopic) {
        $("#output-progress-bar").text(currentItem.children(":not(.circle)").text());
    }
}

export default progressBar;
