import getOffset from "../utils/getOffset";
import DOMPurify from "dompurify";

export default class Webinar {
    constructor() {
        this.key = "webinar";
        if (document.getElementsByTagName("html")[0].getAttribute("lang") == "es-US") {
            this.pageLanguage = "spanish";
        } else {
            this.pageLanguage = "english";
        }

        this.isCancelComponent = document.querySelector(".cancel-event-content");
        this.eventListElement = document.querySelector("[data-webinar-auth]");
        this.eventDetailElement = document.querySelector(".box-event-details");

        if (this.eventListElement) {
            this.eventsApi = this.eventListElement.getAttribute("data-event-url");
        }

        if (!this.isCancelComponent) {
            this.eventId = this.getEventId();
            return this.init();
        }
    }

    async init() {
        // Get access token
        this.accessToken = await this.getAccessToken();
        if (!this.accessToken) {
            return;
        }
        // Event detail
        if (this.eventId) {
            try {
                const dataEvent = await this.getEventById(this.eventId);
                return this.fillEventDetail(dataEvent);
            } catch (error) {
                console.log("error:" + error.message); // eslint-disable-line
            }
        }

        if (!this.eventListElement) {
            return;
        }

        // Event list
        try {
            const dataEvents = await this.getEvents(3);
            const filledEvents = await this.fillEvents(dataEvents);
            return filledEvents;
        } catch (error) {
            console.log("error:" + error.message); // eslint-disable-line
        }
    }
    displayEvents(eventsList, eventMax) {
        // Display first slide
        document.querySelector("#event-list .item").classList.add("active");
        // create paginations
        const paginationWrapper = document.querySelector(".carousel-pagination .carousel-indicators");
        const paginationItem = paginationWrapper.querySelector("li");
        var paginationSlide = 0;
        for (let i = 0; i < eventsList; i += parseInt(eventMax)) {
            let clnItemNode = paginationItem.cloneNode(false);
            if (i == 0) {
                clnItemNode.classList.add("active");
            }
            clnItemNode.setAttribute("data-slide-to", paginationSlide);
            paginationSlide += 1;
            paginationWrapper.append(clnItemNode);
        }
        paginationItem.remove();
        this.controlPagination();
    }
    controlPagination() {
        const maxPagination = document.querySelectorAll(".carousel-indicators li").length;
        const prev = document.querySelector(".controls-wrap .prev");
        const next = document.querySelector(".controls-wrap .next");
        if (maxPagination == 1) {
            next.classList.add("invisible");
        }
        $("#event-list").on("slid.bs.carousel", function() {
            var index = $(this)
                .find(".active")
                .index();
            if (index == 0) {
                prev.classList.add("invisible");
                next.classList.remove("invisible");
            } else if (index == maxPagination - 1) {
                next.classList.add("invisible");
                prev.classList.remove("invisible");
            } else {
                prev.classList.remove("invisible");
                next.classList.remove("invisible");
            }
            //slide to the top
            const wrapper = document.querySelector(".carousel.slide");
            window.scrollTo({ top: getOffset(wrapper), behavior: "smooth" });
        });
    }
    getAccessToken() {
        let dataToken = localStorage.getItem(this.key);
        if (dataToken) {
            dataToken = JSON.parse(localStorage.getItem(this.key));
        }
        if (dataToken && dataToken.expires >= Date.now()) {
            return dataToken.access_token;
        }
        if (dataToken) {
            localStorage.removeItem(this.key);
        }
        let url;
        const webinarElement = document.querySelector("[data-webinar-auth]");
        if (webinarElement) {
            url = webinarElement.getAttribute("data-webinar-auth");
        }
        if (!url) {
            return;
        }
        return fetch(url)
            .then(response => response.json())
            .then(data => {
                data.expires = Date.now() + data.expires_in;
                localStorage.setItem(this.key, JSON.stringify(data));
                return data.access_token;
            })
            .catch(error => {
                console.error(error.message); // eslint-disable-line
                return;
            });
    }

    getEvents(retries) {
        const params = this.getEventsParams();
        if (!params) {
            return;
        }
        const url = this.eventsApi + "events?" + params;
        const headers = this.getDefaultHeaderContentJson();
        return fetch(url, { headers: headers })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                if (retries > 0) {
                    return this.getEvents(retries - 1);
                }
                throw new Error(response.status);
            })
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error.message); // eslint-disable-line
                return;
            });
    }

    getEventById(eventId) {
        if (!eventId) {
            return;
        }
        const url = this.eventsApi + "events/" + eventId;
        return fetch(url, { headers: this.getDefaultHeaderContentJson() })
            .then(response => response.json())
            .then(data => {
                return data.Result;
            })
            .catch(error => {
                console.error(error.message); // eslint-disable-line
                return;
            });
    }

    getEventsParams() {
        let dataFilter = {};
        if (this.eventListElement.getAttribute("data-event-indication")) {
            dataFilter.eventindication = this.eventListElement.getAttribute("data-event-indication").toLowerCase();
        }

        if (this.eventListElement.getAttribute("data-event-types")) {
            dataFilter.eventtype = this.eventListElement.getAttribute("data-event-types");
        }

        if (this.eventListElement.getAttribute("data-event-status")) {
            dataFilter.eventstatus = this.eventListElement.getAttribute("data-event-status");
        }

        if (this.eventListElement.getAttribute("data-event-language")) {
            dataFilter.language = this.eventListElement.getAttribute("data-event-language");
        }

        if (this.eventListElement.getAttribute("data-event-brand")) {
            dataFilter.brand = this.eventListElement.getAttribute("data-event-brand");
        }

        if (this.eventListElement.getAttribute("data-event-latlng")) {
            dataFilter.latlng = this.eventListElement.getAttribute("data-event-latlng");
        }

        if (this.eventListElement.getAttribute("data-event-location")) {
            dataFilter.location = this.eventListElement.getAttribute("data-event-location");
        }

        let twoMoInFuture = this.addMonths(new Date(), 2);
        dataFilter.eventenddateutcbefore = twoMoInFuture.toUTCString();

        dataFilter.eventstartdateutcafter = new Date().toUTCString();

        if (this.eventListElement.getAttribute("data-take")) {
            dataFilter.take = this.eventListElement.getAttribute("data-take");
        }

        if (!dataFilter.eventindication || !dataFilter.eventtype || !dataFilter.eventstatus || !dataFilter.language || !dataFilter.brand) {
            return;
        }

        return new URLSearchParams(dataFilter).toString();
    }
    fillEvents(dataEvents) {
        const eventMax = document.querySelector("#event-list").getAttribute("max-events-displayed");
        const eventList = this.eventListElement.querySelector("#event-list");
        if (dataEvents.Result.length == 0) {
            const noResultElement = this.eventListElement.querySelector("#no-results");
            const explore = document.querySelector("#explore-webinars");
            noResultElement.classList.remove("hidden");
            eventList.classList.add("hidden");
            explore.classList.add("hidden");
            return;
        }
        const carouselInner = document.querySelector(".carousel-inner");
        var eventListElement = this.eventListElement.querySelector(".item");
        for (let j = 0; j < dataEvents.Result.length; j += parseInt(eventMax)) {
            let clnItemNode = eventListElement.cloneNode(false);
            carouselInner.append(clnItemNode);
        }
        eventListElement.remove();
        const eventNode = eventListElement.querySelector(".event-listing");
        eventListElement = this.eventListElement.querySelectorAll(".item");
        var selectedItem = 0;
        for (let i = 0; i < dataEvents.Result.length; i++) {
            if (i != 0 && i % eventMax == 0) {
                selectedItem += 1;
            }
            const event = dataEvents.Result[i];

            let clnEventNode = eventNode.cloneNode(true);
            clnEventNode.removeAttribute("style");

            const eventTitleElement = clnEventNode.querySelector(".event-title");
            const eventSpeakerElement = clnEventNode.querySelector(".speaker");
            const eventDescriptionElement = clnEventNode.querySelector(".event-description");
            const dayElement = clnEventNode.querySelector(".day");
            const monthElement = clnEventNode.querySelector(".month");
            const dateElement = clnEventNode.querySelector(".date");
            const timeElement = clnEventNode.querySelector(".time");
            const registerElement = clnEventNode.querySelector(".register");

            //sanitization
            const eventTitle = DOMPurify.sanitize(event.Title);
            const eventSpeakersFormatted = DOMPurify.sanitize(event.SpeakersFormatted);
            const eventDescription = DOMPurify.sanitize(event.Description);
            const eventStartDate = new Date(DOMPurify.sanitize(event.EventStartDate));
            const eventTimeZone = DOMPurify.sanitize(event.Timezone);

            if (eventTitleElement) {
                eventTitleElement.innerHTML = eventTitle;
            }
            if (eventSpeakerElement) {
                let isEoE = document.body.classList.contains("indication-eoe");
                if (isEoE) {
                    event.SpeakersFormatted = eventSpeakersFormatted.replace(", DUPIXENT", "<br/>DUPIXENT");
                }
                eventSpeakerElement.innerHTML = this.tagWrap("DUPIXENT MyWay", ["strong", "em"], eventSpeakersFormatted, "speaker");
            }

            if (eventDescriptionElement) {
                eventDescriptionElement.innerHTML = this.tagWrap("DUPIXENT MyWay", ["strong", "em"], eventDescription, "description");
            }

            if (dayElement) {
                dayElement.innerText = this.getDayName(eventStartDate.getDay());
            }

            if (monthElement) {
                monthElement.innerText = this.getMonthName(eventStartDate.getMonth());
            }

            if (dateElement) {
                dateElement.innerText = eventStartDate.getDate();
            }

            if (timeElement) {
                timeElement.innerHTML = this.getTimeFormatAmPm(eventStartDate) + "&nbsp;" + eventTimeZone;
            }

            const dataRegisterLink = this.eventListElement.getAttribute("data-event-register");
            if (registerElement && dataRegisterLink) {
                registerElement.href = dataRegisterLink + "?id=" + event.Id;
            }

            eventListElement[selectedItem].append(clnEventNode);
        }
        this.displayEvents(dataEvents.Result.length, eventMax);
        eventList.classList.remove("loading");
        return eventNode.remove();
    }

    tagWrap(word, tag = [], str, context) {
        let sanitizedStr = DOMPurify.sanitize(str);
        let open = [];
        let close = [];
        // Create a regular expression to match the word
        const regex = new RegExp(`\\b${word}\\b`, "gi");
        tag.forEach(t => {
            open.push(`<${t}>`);
            close.push(`</${t}>`);
        });
        // Replace the matched word with the wrapped version
        if (context === "description") {
            return sanitizedStr.replace(regex, open.join("") + DOMPurify.sanitize(word) + close.join(""));
        } else {
            return sanitizedStr.replace(regex, open.join("") + DOMPurify.sanitize(word) + close.join("") + "® ");
        }
    }

    fillEventDetail(dataEvent) {
        if (!dataEvent) {
            return;
        }

        if (!this.eventDetailElement) {
            return;
        }

        const dayElement = document.querySelectorAll(".day");
        const monthElement = document.querySelectorAll(".month");
        const dateElement = document.querySelectorAll(".date");
        const timeElement = document.querySelectorAll(".time");
        const eventTitleElement = document.querySelectorAll(".event-title");
        const eventSpeakerElement = this.eventDetailElement.querySelector(".speaker");
        const eventDescriptionElement = this.eventDetailElement.querySelector(".event-description");

        // Sanitization
        const eventTitle = DOMPurify.sanitize(dataEvent.Title);
        const eventSpeakersFormatted = DOMPurify.sanitize(dataEvent.SpeakersFormatted);
        const eventDescription = DOMPurify.sanitize(dataEvent.Description);
        const eventStartDate = new Date(DOMPurify.sanitize(dataEvent.EventStartDate));
        const eventEndDate = new Date(DOMPurify.sanitize(dataEvent.EventEndDate));
        const eventTimeZone = DOMPurify.sanitize(dataEvent.TimeZone);

        if (eventTitleElement) {
            eventTitleElement.forEach(item => {
                item.innerHTML = eventTitle;
            });
        }

        if (eventSpeakerElement) {
            let isEoE = document.body.classList.contains("indication-eoe");
            if (isEoE) {
                dataEvent.SpeakersFormatted = eventSpeakersFormatted.replace(", DUPIXENT", "<br/>DUPIXENT");
            }
            eventSpeakerElement.innerHTML = this.tagWrap("DUPIXENT MyWay", ["strong", "em"], eventSpeakersFormatted, "speaker");
        }

        if (eventDescriptionElement) {
            eventDescriptionElement.innerHTML = this.tagWrap("DUPIXENT MyWay", ["strong", "em"], eventDescription, "description");
        }

        if (dayElement) {
            dayElement.forEach(item => {
                item.innerText = this.getDayName(eventStartDate.getDay());
            });
        }

        if (monthElement) {
            monthElement.forEach(item => {
                item.innerText = this.getMonthName(eventStartDate.getMonth());
            });
        }

        if (dateElement) {
            dateElement.forEach(item => {
                item.innerText = eventStartDate.getDate();
            });
        }

        if (timeElement) {
            timeElement.forEach(item => {
                item.innerHTML = this.getTimeFormatAmPm(eventStartDate) + "&nbsp;-&nbsp;" + this.getTimeFormatAmPm(eventEndDate) + "&nbsp;" + eventTimeZone;
            });
        }

        this.eventDetailElement.classList.remove("loading");
        const boxEventRegister = document.querySelector(".box-event-reg");
        if (boxEventRegister) {
            boxEventRegister.classList.remove("loading");
        }

        this.formatGoogleCalendarLink(dataEvent, eventStartDate, eventEndDate);
        this.formatYahooCalendarLink(dataEvent, eventStartDate);
        this.formatICalAndOutlookLink(dataEvent, eventStartDate, eventEndDate);
        return;
    }

    getDayName(day) {
        let weekday;

        if (this.pageLanguage == "spanish") {
            weekday = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
        } else {
            weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        }

        if (day < 0 || day > 6) {
            return;
        }

        return weekday[day];
    }

    addMonths(date, months) {
        var d = date.getDate();
        date.setMonth(date.getMonth() + +months);
        if (date.getDate() != d) {
            date.setDate(0);
        }
        return date;
    }

    getMonthName(month) {
        let monthNames;

        if (this.pageLanguage == "spanish") {
            monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        } else {
            monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        }
        if (month < 0 || month > 11) {
            return;
        }

        return monthNames[month];
    }

    getTimeFormatAmPm(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        let strTime = hours + ":" + minutes + "&nbsp;" + ampm;
        return strTime;
    }

    getEventId() {
        return this.getParam("id");
    }

    getParam(param) {
        if (!param) {
            return;
        }

        const url = new URL(DOMPurify.sanitize(window.location.href));
        return url.searchParams.get(param);
    }

    formatGoogleCalendarLink(dataEvent, eventStartDate, eventEndDate) {
        const defaultTitle = "An Informative Healthcare Event";
        const defaultDescription = `During the program, attendees will learn more about DUPIXENT® (dupilumab) and hear personal stories from a DUPIXENT MyWay® ambassador. \nYou'll need your event confirmation email to join the webinar. Click the yellow "Join Webinar" button in the email shortly before your webinar is scheduled to begin.`;

        const googleCalendarElement = document.querySelector("a.google");
        if (googleCalendarElement) {
            const startTime = this.formatTime(eventStartDate);
            const endTime = this.formatTime(eventEndDate);
            const href = encodeURI(["https://www.google.com/calendar/render", "?action=TEMPLATE", "&text=" + defaultTitle, "&dates=" + (startTime || ""), "/" + (endTime || ""), "&details=" + defaultDescription, "&location=" + (dataEvent.LocationName || ""), "&recur=" + (dataEvent.Frequency || ""), "&sprop=&sprop=name:"].join(""));
            googleCalendarElement.href = DOMPurify.sanitize(href);
        }
    }

    formatYahooCalendarLink(dataEvent, eventStartDate) {
        const defaultTitle = "An Informative Healthcare Event";
        const defaultDescription = `During the program, attendees will learn more about DUPIXENT® (dupilumab) and hear personal stories from a DUPIXENT MyWay® ambassador. \nYou'll need your event confirmation email to join the webinar. Click the yellow "Join Webinar" button in the email shortly before your webinar is scheduled to begin.`;
        const yahooCalendarElement = document.querySelector("a.yahoo");
        if (yahooCalendarElement) {
            let eventDuration = dataEvent.Duration.replace(/-|:|\.\d+/g, "");
            eventDuration = eventDuration.slice(0, 4);
            // Remove timezone from event time
            const st = this.formatTime(new Date(new Date(eventStartDate) - new Date(eventStartDate).getTimezoneOffset() * 60 * 1000)) || "";

            const href = encodeURI(["http://calendar.yahoo.com/?v=60&view=d&type=20", "&title=" + defaultTitle, "&st=" + st, "&dur=" + (eventDuration || ""), "&desc=" + defaultDescription, "&in_loc=" + (dataEvent.LocationName || "")].join(""));

            yahooCalendarElement.href = DOMPurify.sanitize(href);
        }
    }

    formatICalAndOutlookLink(dataEvent, eventStartDate, eventEndDate) {
        const iCalElement = document.querySelector("a.ical");
        const outlookElement = document.querySelector("a.outlook");

        //default overrides
        const defaultTitle = "An Informative Healthcare Event";
        const defaultDescription = `During the program, attendees will learn more about DUPIXENT® (dupilumab) and hear personal stories from a DUPIXENT MyWay® ambassador. \\nYou'll need your event confirmation email to join the webinar. Click the yellow "Join Webinar" button in the email shortly before your webinar is scheduled to begin.`;

        if (!outlookElement && !iCalElement) {
            return;
        }

        let isCrappyIE = false;
        if (typeof window !== "undefined" && window.navigator.msSaveOrOpenBlob && window.Blob) isCrappyIE = true;

        const startTime = this.formatTime(eventStartDate);
        const endTime = this.formatTime(eventEndDate);

        let href = ["BEGIN:VCALENDAR", "VERSION:2.0", "BEGIN:VEVENT", "URL:" + "", "DTSTART:" + (startTime || ""), dataEvent.Frequency || "", "DTEND:" + (endTime || ""), "SUMMARY:" + defaultTitle, "DESCRIPTION:" + this.br2nl(defaultDescription), "LOCATION:" + (dataEvent.LocationName || ""), "END:VEVENT", "END:VCALENDAR"].join("\n");

        if (!isCrappyIE) {
            href = encodeURI("data:text/calendar;charset=utf8," + href);
        }

        if (iCalElement) {
            iCalElement.href = DOMPurify.sanitize(href);
        }

        if (outlookElement) {
            outlookElement.href = DOMPurify.sanitize(href);
        }
    }

    formatTime(date) {
        return date.toISOString().replace(/-|:|\.\d+/g, "");
    }

    getDefaultHeaderContentJson() {
        return new Headers({
            Accept: "*/*",
            Authorization: "Bearer " + this.accessToken,
            "Content-Type": "application/json; charset=utf-8"
        });
    }

    getDefaultHeaderContentUrlencoded() {
        return new Headers({
            Accept: "*/*",
            Authorization: "Bearer " + this.accessToken,
            "Content-Type": "application/x-www-form-urlencoded"
        });
    }

    br2nl(str) {
        return str.replace(/<br\s*\/?>/gm, "\\n");
    }
}
//TODO rewrite in readable code
!(function(a) {
    a.fn.niceSelect = function(d) {
        function c(j) {
            j.before(
                a("<div></div>")
                    .addClass("nice-select")
                    .addClass(j.attr("class") || "")
                    .addClass(j.attr("disabled") ? "disabled" : "")
                    .attr("tabindex", j.attr("disabled") ? null : "0")
                    .html('<span class="current"></span><ul class="list"></ul>')
            );
            var h = j.prev(),
                g = j.find("option"),
                f = j.find("option:selected");
            h.find(".current").html(f.data("display") || f.text()),
                // eslint-disable-next-line no-unused-vars
                g.each(function(m) {
                    var l = a(this),
                        k = l.data("display");
                    h.find("ul").append(
                        a("<li></li>")
                            .attr("data-value", l.val())
                            .attr("data-display", k || null)
                            .addClass("option" + (l.is(":selected") ? " selected" : "") + (l.is(":disabled") ? " disabled" : ""))
                            .html(l.text())
                    );
                });
        }
        if ("string" == typeof d) {
            return (
                "update" == d
                    ? this.each(function() {
                          var h = a(this),
                              g = a(this).prev(".nice-select"),
                              f = g.hasClass("open");
                          g.length && (g.remove(), c(h), f && h.next().trigger("click"));
                      })
                    : "destroy" == d
                    ? (this.each(function() {
                          var g = a(this),
                              f = a(this).prev(".nice-select");
                          f.length && (f.remove(), g.css("display", ""));
                      }),
                      0 == a(".nice-select").length && a(document).off(".nice_select"))
                    : console.log('Method "' + d + '" does not exist.'), // eslint-disable-line
                this
            );
        }
        this.hide(),
            this.each(function() {
                var f = a(this);
                f.next().hasClass("nice-select") || c(f);
            }),
            a(document).off(".nice_select"),
            // eslint-disable-next-line no-unused-vars
            a(document).on("click.nice_select", ".nice-select", function(g) {
                var f = a(this);
                a(".nice-select")
                    .not(f)
                    .removeClass("open"),
                    f.toggleClass("open"),
                    f.hasClass("open") ? (f.find(".option"), f.find(".focus").removeClass("focus"), f.find(".selected").addClass("focus")) : f.focus();
            }),
            a(document).on("click.nice_select", function(f) {
                0 === a(f.target).closest(".nice-select").length &&
                    a(".nice-select")
                        .removeClass("open")
                        .find(".option");
            }),
            // eslint-disable-next-line no-unused-vars
            a(document).on("click.nice_select", ".nice-select .option:not(.disabled)", function(j) {
                var h = a(this),
                    g = h.closest(".nice-select");
                g.find(".selected").removeClass("selected"), h.addClass("selected");
                var f = h.data("display") || h.text();
                g.find(".current").text(f),
                    g
                        .next("select")
                        .val(h.data("value"))
                        .trigger("change")
                        .removeClass("invalid")
                        .addClass("success");
                $("#msg-about-program").attr("style", "display:none");
                document
                    .querySelector("[data-validate-id='msg-about-program']")
                    .closest(".dupi-form__group")
                    .classList.remove("form-error");
            }),
            a(document).on("keydown.nice_select", ".nice-select", function(k) {
                var j = a(this),
                    h = a(j.find(".focus") || j.find(".list .option.selected"));
                if (32 == k.keyCode || 13 == k.keyCode) {
                    return j.hasClass("open") ? h.trigger("click") : j.trigger("click"), !1;
                }
                if (40 == k.keyCode) {
                    if (j.hasClass("open")) {
                        var f = h.nextAll(".option:not(.disabled)").first();
                        f.length > 0 && (j.find(".focus").removeClass("focus"), f.addClass("focus"));
                    } else {
                        j.trigger("click");
                    }
                    return !1;
                }
                if (38 == k.keyCode) {
                    if (j.hasClass("open")) {
                        var g = h.nextAll(".option:not(.disabled)").first();
                        g.length > 0 && (j.find(".focus").removeClass("focus"), g.addClass("focus"));
                    } else {
                        j.trigger("click");
                    }
                    return !1;
                }
                if (27 == k.keyCode) {
                    j.hasClass("open") && j.trigger("click");
                } else {
                    if (9 == k.keyCode && j.hasClass("open")) {
                        return !1;
                    }
                }
            });
        var b = document.createElement("a").style;
        return (b.cssText = "pointer-events:auto"), "auto" !== b.pointerEvents && a("html").addClass("no-csspointerevents"), this;
    };
})(jQuery);
$("#event-reg .referral").niceSelect();
